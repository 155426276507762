<template>
  <div class="map-wrap">
    <div id="maplibre" ref="map" class="map"></div>
    <HighFiveComponent v-if="this.trackingData.highFiveEnabled" />
  </div>
</template>

<script>
import { mapState as mapStatePinia } from 'pinia';
import { Map, NavigationControl } from 'maplibre-gl';
import ResizeObserver from 'resize-observer-polyfill';
import HighFiveComponent from '@/components/map/HighFiveComponent.vue';
import { useTrackingDataStore } from '@/stores/trackingData.js';

export default {
  name: 'AwsMap',
  components: { HighFiveComponent },
  props: {
    center: {
      type: Object,
      required: true
    },
    zoom: {
      type: Number,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data'
    })
  },
  async mounted() {
    if (this.center.lng && this.center.lat) await this.initializeMap();
  },
  methods: {
    async initializeMap() {
      if (this.initializing) return;
      this.initializing = true;

      const mapName = 'better-tracking.map';
      const mapApiKey = import.meta.env.VITE_APP_AWS_MAP_API_KEY;
      const styleUrl = `https://maps.geo.eu-central-1.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${mapApiKey}`;

      this.map = new Map({
        container: this.$refs.map,
        center: this.center,
        zoom: this.zoom,
        style: styleUrl,
        hash: false,
        maxZoom: 15,
        scrollZoom: false,
        ...this.options
      });

      if (this.options.interactive) {
        this.map.addControl(new NavigationControl(), 'top-left');
      }

      this.map.on('load', () => {
        const container = this.map.getContainer();

        const resizeObserver = new ResizeObserver(() => {
          this.map.resize();
        });
        resizeObserver.observe(container);

        let lastTouchY;

        this.map.on('touchstart', e => {
          if (e.points?.length > 1) return;
          const isPortrait = window.innerHeight > window.innerWidth;
          if (window.innerWidth > 800 || !isPortrait) return; // keep in sync with css
          const { x, y } = e.point;
          lastTouchY = y;
          const padding = 40;

          if (x < padding || x > container.clientWidth - padding) {
            container.classList.add('scrollBlock');
            e.preventDefault();

            const onMove = e => {
              window.scrollBy({
                left: 0,
                top: lastTouchY - e.point.y
              });
              lastTouchY = e.point.y + (lastTouchY - e.point.y);
            };
            this.map.on('touchmove', onMove);
            this.map.once('touchend', () => {
              container.classList.remove('scrollBlock');
              this.map.off('touchmove', onMove);
            });
          }
        });
      });

      this.$emit('initialized', this.map);
    },
    setInteractivity(activate) {
      const verb = activate ? 'enable' : 'disable';
      this.map.boxZoom[verb]();
      this.map.dragPan[verb]();
      this.map.dragRotate[verb]();
      this.map.keyboard[verb]();
      this.map.doubleClickZoom[verb]();
      this.map.touchZoomRotate[verb]();
      this.map.touchPitch[verb]();

      const classListVerb = activate ? 'add' : 'remove';
      this.$refs.map
        .querySelector('.mapboxgl-canvas-container')
        .classList[
          classListVerb
        ]('maplibregl-interactive', 'mapboxgl-interactive');
    }
  },
  watch: {
    center(center, oldCenter) {
      if (center.lng === oldCenter.lng && center.lat === oldCenter.lat) {
        return;
      }
      if (this.map?.getBounds()?.contains(center) === false) {
        this.map.fitBounds(this.map.getBounds().extend(center), {
          padding: 60,
          animate: false
        });
      }
    },
    zoom(zoom, oldZoom) {
      if (zoom === oldZoom && zoom === oldZoom) return;
      if (this.map) this.map.setZoom(zoom);
    },
    options(options, oldOptions) {
      if (this.map) {
        if (options.interactive === oldOptions.interactive) return;
        if (options.interactive) {
          this.map.addControl(new NavigationControl(), 'top-left');
          this.setInteractivity(true);
        } else {
          this.map._controls
            .find(control => control.constructor.name === 'NavigationControl')
            ?.onRemove(this.map);
          this.setInteractivity(false);
        }
      }
    }
  }
};
</script>

<style>
@import 'maplibre-gl/dist/maplibre-gl.css';

.map-wrap {
  position: relative;
  width: 100%;
  height: calc(
    100vh - 77px
  ); /* calculate height of the screen minus the heading */
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}

/*noinspection CssUnusedSymbol*/
.maplibregl-ctrl-attrib {
  opacity: 0.7;
}

@media all and (max-width: 800px) and (orientation: portrait) {
  .map::before,
  .map::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    width: 0;
    height: 100%;
    opacity: 0;
    transition:
      width 0.2s,
      opacity 0.3s;
    background-color: rgb(0, 0, 0);
  }

  .map::before {
    left: 0;
  }

  .map::after {
    right: 0;
  }

  .map.scrollBlock::before,
  .map.scrollBlock::after {
    width: 40px;
    opacity: 0.3;
  }
}

/*noinspection CssUnusedSymbol*/
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right,
.maplibregl-ctrl-bottom-left,
.maplibregl-ctrl-bottom-right,
.maplibregl-ctrl-top-left,
.maplibregl-ctrl-top-right {
  max-width: 100%;
}

/*noinspection CssUnusedSymbol*/
.maplibregl-ctrl-attrib {
  margin: 2px !important;
  float: none !important;
  left: 0 !important;
  overflow: hidden !important;
  height: 20px !important;
}

/*noinspection CssUnusedSymbol*/
.maplibregl-ctrl-attrib-inner {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
