<template>
  <div>
    <v-card class="mx-0 v-col-12 card_small" variant="flat">
      <div class="pa-0 d-flex flex-column gap-1">
        <p class="font-weight-bold header">
          {{ $t('customization.deliverToParcelShop.header') }}
        </p>
        <p class="text-body-2">
          {{ this.customizedDelivery.parcelShopName }}<br />
          {{ addressString }}
        </p>
      </div>
      <img alt="" src="/parcel_shop_redirect.png" />
    </v-card>
  </div>
</template>

<script>
import { mapState as mapStatePinia } from 'pinia';
import { useResponsiveBreakpointsStore } from '@/stores/responsiveBreakpoints.js';
import { useCustomizedDeliveryStore } from '@/stores/customizedDelivery.js';

export default {
  name: 'ParcelShopRedirect',
  computed: {
    ...mapStatePinia(useCustomizedDeliveryStore, ['customizedDelivery']),
    ...mapStatePinia(useResponsiveBreakpointsStore, [
      'isBelowMobileBreakpoint'
    ]),
    addressString() {
      const a = this.customizedDelivery.parcelShopAddress;
      return `${a.street || ''} ${a.houseNumber || ''}, ${a.zipCode || ''} ${a.city || ''}`;
    }
  }
};
</script>

<style scoped>
.header {
  font-size: 14px;
}

.card_small {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

img {
  height: 4em;
  margin-top: 8px;
  margin-left: 16px;
}
</style>
