<template>
  <v-col
    class="pa-0"
    v-if="
      (this.fromAddress || this.toAddress) &&
      !(
        configGetter($rttI18n.tenantId, this.themeId).disableFromAddress &&
        configGetter($rttI18n.tenantId, this.themeId).disableToAddress
      )
    "
  >
    <div :class="this.isBelowMobileBreakpoint ? 'py-2' : 'py-4'">
      <span class="font-weight-bold">{{ $t(this.addressHeaderKey) }}</span>
    </div>
    <v-card
      v-if="!configGetter($rttI18n.tenantId, this.themeId).disableFromAddress"
      variant="flat"
      class="card_small text-body-2 leading-7 d-flex v-col-12"
    >
      <p class="v-col-6 ma-0 pa-0" v-if="this.fromAddress">
        <span class="font-weight-bold">{{ $t('generic.consignor') }}</span>
        <br />
        {{ this.fromAddress.name }}<br />
        {{ this.fromAddress.street }}
        {{ this.fromAddress.number }}
        <br />
        {{ this.fromAddress.postcode }}
        {{ this.fromAddress.city }}
      </p>
      <p
        class="flex v-col-6 ma-0 pa-0 pl-4"
        v-if="
          this.toAddress &&
          !configGetter($rttI18n.tenantId, this.themeId).disableToAddress
        "
      >
        <span class="font-weight-bold">{{ $t('generic.consignee') }}</span>
        <br />
        {{ this.toAddress.name }}
        <br />
        {{ this.toAddress.street }} {{ this.toAddress.number }}
        <br />
        {{ this.toAddress.postcode }} {{ this.toAddress.city }}
      </p>
    </v-card>
    <v-card
      v-else-if="
        configGetter($rttI18n.tenantId, this.themeId).disableFromAddress
      "
      class="card_small text-body-2"
    >
      <div v-if="this.toAddress">
        <div class="font-weight-bold">{{ this.toAddress.name }}</div>
        {{ this.toAddress.street }} {{ this.toAddress.number
        }}{{
          !this.$rttI18n.isGlsItSelected && this.toAddress.postcode
            ? `, ${this.toAddress.postcode}`
            : ''
        }}
        {{ this.toAddress.city }}
      </div>
    </v-card>
  </v-col>
</template>

<script>
import { configGetter } from '@/config/configGetter';
import { mapState as mapStatePinia } from 'pinia';
import { useResponsiveBreakpointsStore } from '@/stores/responsiveBreakpoints.js';
import { useThemeStore } from '@/stores/theme.js';
import { useTrackingDataStore } from '@/stores/trackingData.js';

export default {
  name: 'DeliveryAddressComponent',
  data() {
    return {
      configGetter
    };
  },
  computed: {
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data',
      isServiceTypePickup: 'isServiceTypePickup'
    }),
    ...mapStatePinia(useResponsiveBreakpointsStore, [
      'isBelowMobileBreakpoint'
    ]),
    ...mapStatePinia(useThemeStore, ['themeId']),
    fromAddress() {
      return this.isServiceTypePickup
        ? this.trackingData.toAddress
        : this.trackingData.fromAddress;
    },
    toAddress() {
      return this.isServiceTypePickup
        ? this.trackingData.fromAddress
        : this.trackingData.toAddress;
    },
    addressHeaderKey() {
      return configGetter(this.$rttI18n.tenantId, this.themeId)
        .disableFromAddress
        ? 'generic.consignee'
        : 'deliveryAddress.header';
    }
  }
};
</script>

<style scoped></style>
