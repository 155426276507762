<template>
  <div>
    <div
      v-if="!this.isBelowMobileBreakpoint && !$store.$isWidget"
      class="v-row v-col-12"
    >
      <h2 class="text-h3 pa-0 pb mr-4 my-4">
        {{ $t('overview.header') }}
      </h2>
    </div>
    <div
      v-if="this.isBelowMobileBreakpoint && !$store.$isWidget"
      class="v-row my-0"
    >
      <v-col
        :style="$rttI18n.isGlsItSelected ? { visibility: 'hidden' } : null"
        class="d-flex text-right align-center font-weight-bold"
      >
        <div>{{ $t('overview.title') }}</div>
      </v-col>
      <v-col class="d-flex align-center justify-end" style="flex-basis: 50%">
        <span class="font-weight-bold">{{ $t('generic.trackingId') }}</span
        >: {{ this.trackingData.trackingId }}
      </v-col>
    </div>
    <v-row class="v-col-12 pa-0 ma-0" data-testId="RealTimeTrackingInProgress">
      <v-col
        v-if="
          this.isBelowMobileBreakpoint &&
          !configGetter(this.$rttI18n.tenantId, this.themeId).disableOverview
        "
        class="v-col-12 pa-0"
      >
        <OverviewComponent v-bind="$attrs" />
        <FeedbackComponent
          v-if="
            this.$rttI18n.isGlsPlSelected &&
            this.trackingData.toAddress?.postcode
          "
          :postcode="this.trackingData.toAddress.postcode"
          :ratingData="this.trackingData.rating"
          :trackingId="this.trackingData.trackingId"
        />
      </v-col>
      <v-col
        :class="this.isBelowMobileBreakpoint ? 'v-col-12' : 'v-col-6'"
        class="pa-0"
      >
        <MapContainer />
        <AdComponent
          v-if="
            !this.isBelowMobileBreakpoint &&
            ((this.trackingData.ad && this.trackingData.ad.length) || 0) < 2 &&
            !configGetter(this.$rttI18n.tenantId, this.themeId).disableOverview
          "
          :adData="this.trackingData.ad"
        />
      </v-col>
      <v-col
        v-if="
          !configGetter(this.$rttI18n.tenantId, this.themeId).disableOverview
        "
        :class="
          this.isBelowMobileBreakpoint ? 'v-col-12 pa-0' : 'v-col-6 pa-0 pl-4'
        "
      >
        <OverviewComponent
          v-if="!this.isBelowMobileBreakpoint"
          v-bind="$attrs"
        />
        <FeedbackComponent
          v-if="
            !this.isBelowMobileBreakpoint &&
            this.$rttI18n.isGlsPlSelected &&
            this.trackingData.toAddress?.postcode
          "
          :postcode="this.trackingData.toAddress.postcode"
          :ratingData="this.trackingData.rating"
          :trackingId="this.trackingData.trackingId"
        />
        <CustomizedDeliveryData
          v-if="
            (this.trackingData.customizedDelivery || this.customizedDelivery) &&
            this.trackingData.toAddress
          "
        />
        <AdComponent
          v-if="this.isBelowMobileBreakpoint"
          :adData="this.trackingData.ad"
        />
        <DeliveryAddressComponent />
      </v-col>
    </v-row>
    <AdComponent
      v-if="
        !this.isBelowMobileBreakpoint &&
        (this.trackingData.ad && this.trackingData.ad.length) >= 2
      "
      :adData="this.trackingData.ad"
    />
  </div>
</template>

<script>
import DeliveryAddressComponent from '../DeliveryAddressComponent';
import MapContainer from '../map/MapContainer';
import OverviewComponent from '../OverviewComponent';
import CustomizedDeliveryData from '../CustomizedDeliveryData';
import AdComponent from '../AdComponent';
import { configGetter } from '@/config/configGetter';
import { mapState as mapStatePinia } from 'pinia';
import FeedbackComponent from '@/components/feedback/FeedbackComponent.vue';
import { useThemeStore } from '@/stores/theme.js';
import { useResponsiveBreakpointsStore } from '@/stores/responsiveBreakpoints.js';
import { useTrackingDataStore } from '@/stores/trackingData.js';
import { useCustomizedDeliveryStore } from '@/stores/customizedDelivery.js';

export default {
  inheritAttrs: false,
  name: 'RealTimeTrackingInProgress',
  components: {
    FeedbackComponent,
    CustomizedDeliveryData,
    DeliveryAddressComponent,
    MapContainer,
    OverviewComponent,
    AdComponent
  },
  data() {
    return {
      configGetter
    };
  },
  computed: {
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data'
    }),
    ...mapStatePinia(useThemeStore, ['themeId']),
    ...mapStatePinia(useResponsiveBreakpointsStore, [
      'isBelowMobileBreakpoint'
    ]),
    ...mapStatePinia(useCustomizedDeliveryStore, ['customizedDelivery'])
  }
};
</script>

<style scoped></style>
