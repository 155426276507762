import defaultConfig from '@/config/defaultConfig';
import tenants from '@/config/tenants.js';

export const configGetter = (tenantId, themeId) => ({
  ...(tenants[tenantId] || {}),
  ...(defaultConfig.themeConfig[themeId] || {}),
  ...defaultConfig.widgetConfiguration,
  API_URL:
    defaultConfig.widgetConfiguration?.trackingApiUrl ||
    import.meta.env.VITE_APP_API_URL ||
    window.location.origin + '/v1',
  WEBSOCKET_URL:
    defaultConfig.widgetConfiguration?.trackingUpdatesUrl ||
    import.meta.env.VITE_APP_WEBSOCKET_URL ||
    `ws${window.location.protocol === 'https:' ? 's' : ''}://${window.location.hostname}:8080/`
});
