import Vuex from 'vuex';
import { createActions } from './actions';
import getters from './getters';
import mutations from './mutations';

export function setupVuex(app, store) {
  app.use(Vuex);
  app.use(store);
}

const state = {
  message: {
    modal: false
  },
  isPrivate: false,
  loadingProcesses: {},
  trackingData: {
    supportedCustomizedDeliveryTypes: [],
    status: {
      remainingStops: 0
    },
    maxStopsToShowDriverLocation: 10
  },
  socket: null,
  isSubscriptionActive: false,
  updateAvailable: false
};

export function createStore() {
  return new Vuex.Store({
    name: 'store',
    state: { ...state },
    actions: createActions(),
    getters,
    mutations,
    strict: import.meta.env.NODE_ENV !== 'production'
  });
}
