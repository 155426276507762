<template>
  <v-card
    class="card_big v-col-12 tip-card"
    :class="stage === 1 ? 'banner' : ''"
    data-testid="tipping-card"
  >
    <template v-if="stage === 1">
      <div class="d-flex flex-column gap-3">
        <img class="hero d-block" alt="Driver" src="/tipping-hero.png" />
        <div>
          {{ $t('tipping.content') }}
        </div>
        <v-btn
          color="primary"
          :block="true"
          class="text-body-1"
          @click="showAmounts"
        >
          {{ $t('tipping.button') }}
        </v-btn>
      </div>
    </template>
    <template v-else-if="stage === 2">
      <div class="d-flex flex-column gap-5">
        <div class="font-weight-bold">{{ $t('tipping.details') }}</div>
        <div class="d-flex flex-row gap-3 justify-center">
          <v-btn
            v-for="amount in amounts"
            :key="amount"
            :color="amount !== selectedAmount ? 'primary' : 'info'"
            :block="false"
            class="text-body-1"
            @click="handlePillClick(amount)"
          >
            {{ amount }} €
          </v-btn>
        </div>
        <PaypalButton
          @paymentSuccess="paymentSuccessHandler"
          :amount="selectedAmount"
        />
      </div>
    </template>
    <template v-else-if="stage === 3">
      <LottieAnimation
        file="/animations/success-firework.json"
        containerStyle="top: -100px"
        playerStyle="max-width: 400px"
        :loop="true"
      ></LottieAnimation>
      <span class="close-button" @click="handleClose">×</span>
      <div class="font-weight-bold tipping-success">
        {{ $t('tipping.success') }}
      </div>
    </template>
  </v-card>
</template>

<script>
import { configGetter } from '@/config/configGetter';
import PaypalButton from '@/components/PaypalButton.vue';
import { sendAnalytics } from '@/services/analytics';
import LottieAnimation from '@/components/ui/LottieAnimation.vue';
import { mapState as mapStatePinia } from 'pinia';
import { useResponsiveBreakpointsStore } from '@/stores/responsiveBreakpoints.js';
import { useTrackingDataStore } from '@/stores/trackingData.js';

export default {
  name: 'TippingComponent',
  components: {
    LottieAnimation,
    PaypalButton
  },
  data() {
    return {
      configGetter,
      stage: 1,
      amounts: [1, 2, 3, 5],
      selectedAmount: undefined
    };
  },
  computed: {
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data'
    }),
    ...mapStatePinia(useResponsiveBreakpointsStore, ['isBelowMobileBreakpoint'])
  },
  methods: {
    showAmounts() {
      this.stage = 2;
      sendAnalytics(this.trackingData, 'tipping', 'tipping_button_clicked', {});
    },
    handlePillClick(amount) {
      this.selectedAmount = amount;
    },
    paymentSuccessHandler() {
      this.stage = 3;
    },
    handleClose() {
      this.$emit('closeTipping');
    }
  }
};
</script>

<style scoped>
.tip-card {
  margin-bottom: 10px;
  text-align: center;
}

.banner {
  background: white url('/tipping-banner.webp') 50% -10px;
  background-size: auto 38%;
  background-repeat: repeat-x;
}

.hero {
  width: 22%;
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.tip-card li.amount-pill {
  display: inline-block;
  padding: 0.6em 1.5em;
  border-radius: 9em;
  background: rgb(var(--v-theme-primary));
  margin: 0.5em;
  color: #061ab1;
}

.tip-card li.active {
  background: #ffd100;
}

.tip-card li.amount-pill:hover {
  background: #d6ae00;
  cursor: pointer;
}

.tipping-success {
  padding-top: 3em;
  padding-bottom: 3em;
}

span.close-button {
  float: right;
  font-size: 2em;
  margin: 0;
  cursor: pointer;
  line-height: 0.5em;
}
</style>
