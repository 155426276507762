import LocaleContainer from '@/components/LocaleContainer';
import LegalContainer from '@/components/LegalContainer';
import RealTimeTrackingContainer from '@/components/rtt/RealTimeTrackingContainer';
import { LOCAL_STORAGE_PREVIEW_KEY } from '@/store/constants.js';

const LandingContainer = () => import('@/components/LandingContainer');
const LegacyDesign = () => import('@/pages/index/LegacyDesign.vue');
const NewDesignLandingContainer = () =>
  import('@/v2/components/LandingContainer');
const NewDesignRealTimeTrackingContainer = () =>
  import('@/v2/components/RealTimeTrackingContainer.vue');
const NewDesign = () => import('@/pages/index/NewDesign.vue');

const imprintRoutes = [
  'impressum',
  'imprint',
  'impronta',
  'impresum',
  'felhasználási-szabályzat',
  'mentions-légales',
  'tisk',
  'nadruk',
  'aviso-legal',
  'bedrijfsinformatie',
  'juridische-informatie',
  'mențiuni-legale',
  'обележје',
  'odtlačok',
  'odtis',
  'юридическая-информация',
  'aftryk'
].map(path => ({
  component: LegalContainer,
  name: `imprint.${path}`,
  path
}));

const legalRoutes = [
  'adatkezelesi-tajekoztato',
  'soukromi',
  'privatnost',
  'datenschutz',
  'privacy',
  'confidentialite',
  'privacidad',
  'prywatność',
  'confidențialitate',
  'конфиденциальность',
  'приватност',
  'ochrana-osobných-údajov',
  'zasebnost'
].map(path => ({
  component: LegalContainer,
  name: `privacy.${path}`,
  path
}));

const contactRoutes = [
  'kontakt',
  'kontakty',
  'contact',
  'contatto',
  'kapcsolat',
  'contatti',
  'contacto',
  'контакт',
  'persoană-de-contact',
  'stik'
].map(path => ({
  component: LegalContainer,
  name: `contact.${path}`,
  path
}));

const routes = [
  {
    component: LegacyDesign,
    name: 'legacyLanding',
    path: '/',
    children: [
      {
        component: LocaleContainer,
        path: ':countryOrTenantId/:locale',
        name: 'localeContainer',
        props: true,
        children: [
          ...imprintRoutes,
          ...legalRoutes,
          ...contactRoutes,
          {
            component: LandingContainer,
            name: 'localeLandingContainer',
            path: ''
          },
          {
            component: RealTimeTrackingContainer,
            name: 'realTimeTracking',
            path: ':trackingId/:postcode?',
            props: true
          }
        ]
      },
      {
        component: LandingContainer,
        name: 'landingContainer', // adapt LandingContainer if you change name
        path: ''
      }
    ]
  },
  // Add preview routes only if key is set in localStorage
  ...(localStorage.getItem(LOCAL_STORAGE_PREVIEW_KEY) === 'true'
    ? [
        {
          component: NewDesign,
          name: 'previewLanding',
          path: '/preview',
          children: [
            {
              component: LocaleContainer,
              path: ':countryOrTenantId/:locale',
              name: 'previewLocaleLanding',
              props: true,
              children: [
                ...imprintRoutes,
                ...legalRoutes,
                ...contactRoutes,
                {
                  component: NewDesignLandingContainer,
                  name: 'previewLocaleLandingContainer',
                  path: ''
                },
                {
                  component: NewDesignRealTimeTrackingContainer,
                  name: 'previewRealTimeTracking',
                  path: ':trackingId/:postcode?',
                  props: true
                }
              ]
            },
            {
              component: NewDesignLandingContainer,
              name: 'previewLandingContainer',
              path: ''
            }
          ]
        }
      ]
    : [])
];

export default routes;
