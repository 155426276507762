<template>
  <v-checkbox
    v-model="this.consent"
    @update:model-value="setGlobalConsentState"
    color="anchor"
    required
  >
    <template v-slot:label>
      <span
        v-html="$rttI18n.tenantSpecificContent('consentDeclaration')"
        v-on:click.stop
      />
    </template>
  </v-checkbox>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useGdprStore } from '@/stores/gdpr.js';

export default {
  name: 'GDPRConsentComponent',
  computed: {
    ...mapState(useGdprStore, ['gdprConsent']),
    consent() {
      return !!this.gdprConsent[this.$rttI18n.tenantId];
    }
  },
  methods: {
    ...mapActions(useGdprStore, ['setGdprConsent']),
    updateLocalConsentState(checked) {
      this.consent = !!this.gdprConsent[this.$rttI18n.tenantId];
    },
    setGlobalConsentState(checked) {
      this.setGdprConsent({
        tenantId: this.$rttI18n.tenantId,
        value: checked
      });
    }
  }
};
</script>

<style scoped></style>
