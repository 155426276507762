import {
  ACTION_CUSTOMIZED_DELIVERY,
  ACTION_NOTIFICATION_SUBSCRIPTION,
  ACTION_PUSH_NOTIFICATION_SUBSCRIPTION,
  ACTION_TYPE_INFO,
  TRACKING_STATE_THRESHOLD_MED
} from './constants';
import { defaultThemeName } from '@/config/themes.js';

export default {
  decrementProcesses(state, process) {
    const processName = process ? process : 'root';
    if (!state.loadingProcesses[processName]) {
      state.loadingProcesses[processName] = 0;
    } else {
      state.loadingProcesses[processName] -= 1;
    }
  },
  incrementProcesses(state, process) {
    const processName = process ? process : 'root';
    if (!state.loadingProcesses[processName]) {
      state.loadingProcesses[processName] = 1;
    } else {
      state.loadingProcesses[processName] += 1;
    }
  },
  setSocket(state, socket) {
    state.socket = socket;
  },
  setSubscriptionActive(state, isActive) {
    state.isSubscriptionActive = isActive;
  },
  updateMessage(state, message) {
    state.message = message;
  },
  updateThemeId(state, themeId) {
    state.themeId = themeId || defaultThemeName();
  },
  updateAdditionalDeliveryData(state, payload) {
    state.trackingData = null;
    state.trackingData = payload;
  },
  updateCustomizedDelivery(state, payload) {
    if (payload.customizedDelivery) {
      state.trackingData.customizedDelivery = payload.customizedDelivery;
      state.message = {
        msg: ACTION_CUSTOMIZED_DELIVERY,
        type: ACTION_TYPE_INFO
      };
    }
  },
  updateNotificationSubscription(state, payload) {
    state.message = {
      msg: ACTION_NOTIFICATION_SUBSCRIPTION,
      type: ACTION_TYPE_INFO
    };
    state.trackingData.smsNotification = {
      type: 'SET',
      phone: payload.phone
    };
  },
  updatePushNotification(state, payload) {
    state.message = {
      msg: ACTION_PUSH_NOTIFICATION_SUBSCRIPTION,
      type: ACTION_TYPE_INFO
    };
    state.trackingData.pushNotification = {
      state: 'SET',
      minutesBeforeDelivery: payload.minutesBeforeDelivery
    };
  },
  updateFailure(state, payload) {
    state.message = payload;
  },
  updateRatingData(state, payload) {
    state.trackingData.rating = payload;
  },
  updatePersonalTrackingData(state, payload) {
    //state.trackingData = null;
    state.trackingData = payload;
    state.trackingData.updateTimestamp = new Date().getTime();
    state.isPrivate = true;
  },
  updateTrackingData(state, payload) {
    state.trackingData = null;
    state.trackingData = {
      ...payload,
      // 'maxStopsToShowDriverLocation' is only part of the private parcel response
      maxStopsToShowDriverLocation:
        payload.maxStopsToShowDriverLocation ?? TRACKING_STATE_THRESHOLD_MED,
      updateTimestamp: new Date().getTime()
    };
    state.isPrivate = false;
  },
  setUpdateAvailable(state, payload) {
    state.updateAvailable = payload;
  }
};
