import { defineStore } from 'pinia';
import { LOCAL_STORAGE_GDPR_KEY } from '@/store/constants.js';
import { configGetter } from '@/config/configGetter.js';

export const useGdprStore = defineStore('gdpr', {
  state: () => ({
    gdprConsent: JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_GDPR_KEY) || '{}'
    )
  }),
  actions: {
    setGdprConsent(payload) {
      this.gdprConsent[payload.tenantId] = payload.value;
      if (
        !configGetter(payload.tenantId).resetGdpr ||
        payload.value === false
      ) {
        localStorage.setItem(
          LOCAL_STORAGE_GDPR_KEY,
          JSON.stringify(this.gdprConsent)
        );
      }
    }
  },
  getters: {
    hasGdprConsent: state => tenantId => {
      return state.gdprConsent[tenantId];
    }
  }
});
