import { defineStore } from 'pinia';
import { configGetter } from '@/config/configGetter.js';
import { fetchWithBaseParams } from '@/util/fetch.js';

export const useCustomizedDeliveryStore = defineStore('customizedDelivery', {
  state: () => ({ requestState: undefined, customizedDelivery: undefined }),
  actions: {
    resetRequestState() {
      this.requestState = undefined;
    },
    setCustomizedDelivery(payload) {
      this.customizedDelivery = payload;
    },
    async addCustomizedDelivery(payload) {
      const url = `${configGetter().API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/customization`;
      const body = { ...payload.body };

      try {
        this.requestState = 'LOADING';

        const response = await fetchWithBaseParams(
          url,
          'PUT',
          payload,
          {},
          {
            body: JSON.stringify(body)
          }
        );

        if (response.customizedDelivery) {
          this.customizedDelivery = response.customizedDelivery;
          this.requestState = 'SUCCESS';
        }
      } catch (e) {
        this.requestState = 'ERROR';
      }
    }
  }
});
