<template>
  <Transition>
    <div
      v-if="!submitted"
      :class="`${this.open ? 'open' : 'closed'} container`"
    >
      <div class="icon clap" @click="this.open ? submit() : toggleOpen()">
        <svg
          width="21"
          height="24"
          :class="`${this.submitting ? 'submitting' : ''} ${this.clicked ? 'clicked' : ''}`"
          viewBox="0 0 21 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.20331 22.3274C4.20983 20.5167 3.52784 17.6371 4.39868 15.0956L6.63349 8.62739C6.86431 7.96203 7.73515 7.87477 8.08139 8.48559L8.40664 9.08551C8.65845 9.51091 8.71091 10.0345 8.55353 10.5035L7.52531 13.4922L7.97647 13.9067L14.2297 6.54404C14.597 6.10773 15.2265 6.0641 15.6566 6.44587C16.0658 6.82763 16.1078 7.493 15.7511 7.9184L11.0821 13.4049L11.6907 13.9612L17.4088 7.22031C17.776 6.784 18.4161 6.74037 18.8252 7.12214C19.2449 7.50391 19.2869 8.18018 18.8987 8.60558L13.191 15.3465L13.7996 15.9028L18.7203 10.0999C19.0875 9.66362 19.7276 9.61999 20.1368 10.0018C20.5459 10.3835 20.5984 11.0489 20.2312 11.4525L15.3104 17.2771L15.9085 17.8334L19.2344 13.9176C19.6017 13.4813 20.2417 13.4377 20.6614 13.8194C21.081 14.2012 21.1125 14.8666 20.7453 15.2701L15.2894 21.7274C12.9392 24.5089 8.86829 24.7707 6.20331 22.3274ZM10.0749 8.96553L13.0546 5.45327C13.3064 5.15877 13.6212 4.90789 13.9674 4.76609L14.3661 3.9153C14.6179 3.40264 14.4081 2.77 13.9045 2.51912C13.4114 2.26825 12.8028 2.4864 12.5615 2.99906L9.92799 8.60558C9.98045 8.72556 10.0434 8.84554 10.0749 8.96553ZM9.45585 7.6348V7.68934L12.3726 1.50471C12.6035 0.981148 12.4041 0.359414 11.9005 0.108538C11.4074 -0.147791 10.7988 0.0649077 10.5575 0.588474L7.78761 6.4895C8.4696 6.60948 9.06764 7.01306 9.45585 7.6348ZM2.9193 14.5393L5.15411 8.0711C5.43739 7.25303 6.09839 6.69674 6.8853 6.51131L9.13059 1.72287C9.37191 1.1993 9.16207 0.577566 8.66895 0.326691C8.16533 0.0758153 7.56728 0.28306 7.32596 0.806626L3.16062 9.6418L2.63602 9.36911L2.85635 6.19499C2.89832 5.70415 2.70946 5.21331 2.37372 4.86426L1.89108 4.36251C1.39796 3.89348 0.590068 4.20981 0.537608 4.90789L0.0130051 11.7688C-0.123391 13.9939 0.820893 16.1209 2.47864 17.4517C2.45765 16.4918 2.59405 15.4992 2.9193 14.5393Z"
          />
        </svg>
      </div>
      <span
        >{{ firstHalf($t('highFive.button')) }}<br />{{
          secondHalf($t('highFive.button'))
        }}</span
      >
      <div class="icon close" @click="toggleOpen">
        <img src="/close.png" />
      </div>
    </div>
  </Transition>
</template>

<script>
import { sendAnalytics } from '@/services/analytics.js';
import { mapState as mapStatePinia } from 'pinia';
import { useTrackingDataStore } from '@/stores/trackingData.js';

export default {
  name: 'HighFiveComponent',
  data() {
    return {
      clicked: false,
      open: false,
      opened: false,
      submitted: false,
      submitting: false
    };
  },
  computed: {
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data'
    })
  },
  methods: {
    firstHalf(string) {
      return string
        .split(' ')
        .slice(0, string.split(' ').length / 2 + 1)
        .join(' ');
    },
    secondHalf(string) {
      return string
        .split(' ')
        .slice(string.split(' ').length / 2 + 1)
        .join(' ');
    },
    toggleOpen() {
      this.open = !this.open;
      if (this.opened === false) {
        sendAnalytics(this.trackingData, 'high_five', 'button_opened');
        this.opened = true;
      }
    },
    submit() {
      this.clicked = true;
      setTimeout(() => (this.submitting = true), 450);
      setTimeout(() => {
        this.submitted = true;
        sendAnalytics(this.trackingData, 'high_five', 'button_submitted');
      }, 1000);
    }
  }
};
</script>

<style scoped>
div.container {
  transition: 0.3s;
  border-radius: 3em;
  position: absolute;
  top: 1em;
  right: 1em;
  color: white;
  display: flex;
  background-color: #061ab1;
  gap: 1em;
  padding: 0.75em;
  z-index: 3;
}

div.icon {
  border-radius: 50%;
  padding: 8px;
}

div.icon.clap {
  background-color: #3f50c3;
  top: 50%;
  left: 50%;
  border-radius: 50%;
}

div.icon.close img {
  height: 14px;
  width: 14px;
}

div.icon.clap svg {
  height: 24px;
  width: 24px;
  display: block;
  fill: white;
}

div.icon.clap svg.submitting {
  fill: #ffd100;
}

div.container span {
  align-self: center;
  font-size: 0.8em;
  overflow: hidden;
  white-space: nowrap;
}

div.container.open {
  max-width: 30em;
}

div.container.closed {
  max-width: 4em;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

svg.clicked {
  animation-name: clap;
  animation-duration: 0.5s;
}

@keyframes clap {
  100% {
    transform: rotate(0deg);
  }
  66% {
    transform: rotate(-30deg);
  }
  33% {
    transform: rotate(30deg);
  }
}
</style>
