export const MOBILE_BREAKPOINT = 960;
export const TABLET_BREAKPOINT = 1280;

export const TRACKING_STATE_THRESHOLD_MED = 10;
export const TRACKING_STATE_THRESHOLD_MIN = 1;
export const TRACKING_STATE_THRESHOLD_BLURRY = 0;

export const TRACKING_STATE_DELIVERED = 'DELIVERED';
export const TRACKING_STATE_NOT_DELIVERED = 'NOT_DELIVERED';
export const TRACKING_STATE_OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY';
export const TRACKING_STATE_UNKNOWN = 'UNKNOWN';
export const TRACKING_STATE_BLOCKED = 'BLOCKED';
export const TRACKING_STATE_SHADOWED = 'SHADOWED';

export const JOBTYPE_PICKUP = 'PICKUP';

export const TRACKING_STATE_PICKED_UP = 'PICKED_UP';
export const TRACKING_STATE_NOT_PICKED_UP = 'NOT_PICKED_UP';

export const TRACKING_DELIVERY_STATE_FAR = 'far';
export const TRACKING_DELIVERY_STATE_CLOSE = 'close';
export const TRACKING_DELIVERY_STATE_NEAR = 'near';
export const TRACKING_DELIVERY_STATE_LAST = 'last';
export const TRACKING_DELIVERY_STATE_UNCLEAR = 'unclear';

export const TRACKING_STATE_DETAIL_PARCEL_SHOP = 'PARCEL_SHOP';
export const TRACKING_STATE_DETAIL_NEIGHBOR = 'NEIGHBOR';

// delta in milliseconds
export const TRACKING_STATE_ETA_VALID_DELTA = -(15 * 60 * 1000); // 15 minutes past

export const NOTIFICATION_TYPE_ELIGIBLE = 'ELIGIBLE';
export const NOTIFICATION_TYPE_SET = 'SET';
export const DELIVER_TO_NEIGHBOUR = 'DELIVER_TO_NEIGHBOR';
export const DEPOSIT_PERMISSION = 'DEPOSIT_PERMISSION';
export const DELIVER_TO_PARCELSHOP = 'DELIVER_TO_PARCELSHOP';
export const ACTION_CUSTOMIZED_DELIVERY = 'customized_delivery';
export const ACTION_NOTIFICATION_SUBSCRIPTION = 'notification_subscribed';
export const ACTION_PUSH_NOTIFICATION_SUBSCRIPTION =
  'push_notification_subscribed';

export const ACTION_TYPE_INFO = 'info';
export const ACTION_TYPE_ERROR = 'error';
export const ACTION_TYPE_SUCCESS = 'success';

export const PROCESS_ADD_CUSTOMIZED_DELIVERY = 'add_customized_delivery';
export const PROCESS_ADD_RATING = 'add_rating';
export const PROCESS_ADD_NOTIFICATION_SUBSCRIPTION =
  'add_notification_subscription';
export const PROCESS_LOAD_ADDITIONAL_TRACKING_DATA =
  'load_additional_tracking_data';
export const PROCESS_LOAD_TRACKING_DATA = 'load_tracking_data';

export const LOCAL_STORAGE_TENANT_KEY = 'rtt.selectedTenant';
export const LOCAL_STORAGE_LOCALE_KEY = 'rtt.selectedLocale';
export const LOCAL_STORAGE_GDPR_KEY = 'rtt.gdpr';
export const LOCAL_STORAGE_DELIVERY_PERIOD_KEY = 'rtt.deliveryPeriodBeta';
export const LOCAL_STORAGE_PREVIEW_KEY = 'rtt.preview';

export const ETA_DEVIATION_VERY_EARLY = 'VERY_EARLY';
export const ETA_DEVIATION_VERY_DELAYED = 'VERY_DELAYED';
export const UPDATE_TRACKING_EVENT = 'UPDATE_TRACKING_EVENT';

export const DELIVERY_NOTE = 'HINT';

export const PROCESS_SEND_TIME_FRAME_FAILURE = 'send_time_frame_failure';
