<template>
  <div
    v-if="
      !(
        [TENANT_IDS.ROYALMAIL_UK, TENANT_IDS.ROYALMAIL_UK_TEST].includes(
          this.$rttI18n.tenantId
        ) &&
        this.getTrackingState === this.TRACKING_STATE_UNKNOWN &&
        $store.$isWidget
      )
    "
  >
    <div
      :style="currentTheme.disableTrackingId ? { visibility: 'hidden' } : null"
      v-if="!this.isBelowMobileBreakpoint && !$store.$isWidget"
      class="py-4"
    >
      <span class="font-weight-bold">{{ $t('generic.trackingId') }}</span
      >: {{ this.trackingData.trackingId }}
    </div>
    <AwsMapComponent
      :showLiveMap="this.isPrivate"
      :trackingId="this.trackingData.trackingId"
    />
  </div>
</template>

<script>
import { configGetter } from '@/config/configGetter';
import { mapState as mapStatePinia } from 'pinia';
import {
  TRACKING_DELIVERY_STATE_CLOSE,
  TRACKING_DELIVERY_STATE_LAST,
  TRACKING_STATE_DETAIL_PARCEL_SHOP,
  TRACKING_STATE_SHADOWED,
  TRACKING_STATE_UNKNOWN
} from '@/store/constants';
import AwsMapComponent from '@/components/map/AwsMapComponent';
import { TENANT_IDS } from '@/config/tenants.js';
import { useThemeStore } from '@/stores/theme.js';
import { useResponsiveBreakpointsStore } from '@/stores/responsiveBreakpoints.js';
import { useTrackingDataStore } from '@/stores/trackingData.js';

export default {
  name: 'MapContainer',
  components: {
    AwsMapComponent
  },
  data() {
    return {
      showLiveMap: undefined,
      parcelShopDialog: false,
      TRACKING_DELIVERY_STATE_CLOSE,
      TRACKING_STATE_DETAIL_PARCEL_SHOP,
      TRACKING_DELIVERY_STATE_LAST,
      TRACKING_STATE_SHADOWED,
      TRACKING_STATE_UNKNOWN,
      TENANT_IDS,
      configGetter
    };
  },
  computed: {
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data',
      isPrivate: 'isPrivate',
      getDeliveryStatusDetail: 'getDeliveryStatusDetail',
      getTrackingState: 'getTrackingState'
    }),
    ...mapStatePinia(useResponsiveBreakpointsStore, [
      'isBelowMobileBreakpoint'
    ]),
    ...mapStatePinia(useThemeStore, ['themeId']),
    currentTheme() {
      return this.configGetter(this.$rttI18n.tenantId, this.themeId);
    }
  }
};
</script>

<style scoped></style>
