import { defineStore } from 'pinia';

/**
 * @typedef {Object} LoadingProcesses
 */
export const useLoadingProcesses = defineStore('loadingProcesses', {
  /**
   * @returns {LoadingProcesses}
   */
  state: () => ({
    loadingProcesses: {}
  }),
  actions: {
    incrementProcesses(process) {
      const processName = process ? process : 'root';
      if (!this.loadingProcesses[processName]) {
        this.loadingProcesses[processName] = 1;
      } else {
        this.loadingProcesses[processName] += 1;
      }
    },
    decrementProcesses(process) {
      const processName = process ? process : 'root';
      if (!this.loadingProcesses[processName]) {
        this.loadingProcesses[processName] = 0;
      } else {
        this.loadingProcesses[processName] -= 1;
      }
    }
  }
});
