import { defineStore } from 'pinia';

export const useDialogStore = defineStore('dialog', {
  state: () => ({
    activeDialog: null,
    dialogPayload: {}
  }),
  actions: {
    toggleDialog(payload) {
      let dialog = payload.dialog || payload;
      if (dialog === this.activeDialog) {
        dialog = null;
      }
      const dialogPayload = payload.payload;

      if (dialogPayload) {
        this.dialogPayload = dialogPayload;
      }
      this.activeDialog = dialog;
    }
  }
});
