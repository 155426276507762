import { computed, nextTick, reactive } from 'vue';
import { createI18n } from 'vue-i18n';
import get from 'lodash/get';
import tenants, { TENANT_IDS } from '@/config/tenants.js';
import locales from '@/config/locales.js';
import {
  LOCAL_STORAGE_LOCALE_KEY,
  LOCAL_STORAGE_TENANT_KEY
} from '@/store/constants.js';
import { tenantsForCustomUi } from '@/config/customUITenants.js';
import config from '@/config/defaultConfig.js';

function setupI18n(locale, i18nOptions) {
  // be aware that this is not registered via app.use but instead proxied through rttI18n
  // so that locale changes always go through rttI18n
  // If you are missing a property add it below
  const i18n = createI18n({
    locale,
    silentFallbackWarn: config.env !== 'development',
    silentTranslationWarn: config.env !== 'development',
    messages: { en: {} },
    messageResolver: (obj, path) =>
      get(obj, path, '')?.replaceAll(/@/g, "{'@'}") || null,
    ...i18nOptions
  });
  setI18nLanguage(i18n, locale);
  return i18n;
}

function setI18nLanguage(i18n, locale, isWidget) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  if (!isWidget) {
    document.querySelector('html').setAttribute('lang', locale);
    if (i18n.global.messages[locale]?.generic?.documentTitle) {
      document.title = i18n.global.messages[locale].generic.documentTitle;
    }
  }
}

async function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(`../locales/${locale}.json`);

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}

// i18n.te function is not fallback aware
function translateOrEmpty(translateFunction, key, props) {
  const translation = translateFunction(key, props);
  if (translation === key) return '';
  return translation;
}

function tOrEmpty(i18n, key, props) {
  return translateOrEmpty(i18n.t, key, props);
}

export function getStartLocale(locale) {
  const savedLocale = localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY);
  const browserLocale = navigator.language;
  const browserLanguage = navigator.language.slice(0, 2);
  const supportedLocales = Object.keys(locales);

  // TODO change order : need to make rttI18n also the truth instead of only router
  if (locale) return locale;
  if (savedLocale) return savedLocale;
  if (supportedLocales.includes(browserLocale)) return browserLocale;
  if (supportedLocales.includes(browserLanguage)) return browserLanguage;
  return 'en';
}

// is it not a real plugin because
export default function createRttI18n({
  locale: startLocale,
  tenantId,
  isWidget,
  i18nOptions = {}
}) {
  const i18n = setupI18n(startLocale, i18nOptions);
  const vueI18n = i18n.global;

  const $rttI18n = reactive({
    i18n,
    isLocaleDirty: false,
    locale: startLocale,
    tenantId: tenantId || localStorage.getItem(LOCAL_STORAGE_TENANT_KEY),
    async setLocale(newLocale) {
      if (newLocale.includes('-'))
        await Promise.all([
          loadLocaleMessages(i18n, newLocale.substring(0, 2)),
          loadLocaleMessages(i18n, newLocale)
        ]);
      else await loadLocaleMessages(i18n, newLocale);

      setI18nLanguage(i18n, newLocale, isWidget);
      this.isLocaleDirty = true;
      this.locale = newLocale;
      localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, newLocale);
    },
    setTenantId(newTenant) {
      localStorage.setItem(LOCAL_STORAGE_TENANT_KEY, newTenant);
      this.tenantId = newTenant;
    },
    isGlsEsSelected: computed(function () {
      return $rttI18n.tenantId === tenantsForCustomUi.glsEs;
    }),
    isGlsItSelected: computed(function () {
      return $rttI18n.tenantId === tenantsForCustomUi.glsIt;
    }),
    isRoyalMailSelected: computed(function () {
      return (
        $rttI18n.tenantId === tenantsForCustomUi.royalMailUK ||
        $rttI18n.tenantId === tenantsForCustomUi.royalMailUKTest
      );
    }),
    isGlsPlSelected: computed(function () {
      return $rttI18n.tenantId === TENANT_IDS.GLS_PL;
    }),
    country: computed(function (...params) {
      return tenants[$rttI18n.tenantId]?.country.code;
    }),
    getLocaleForTenantChange(tenantId) {
      return this.isLocaleDirty
        ? this.locale
        : tenants[tenantId].country.defaultLocale.code;
    },
    getLocaleWithDefaultCountryCode() {
      return this.locale.includes('-')
        ? this.locale
        : `${this.locale}-${locales[this.locale].defaultRegion}`;
    },
    tenantSpecificContent(keyPostFix, props) {
      const key = `tenantSpecificContent.${this.tenantId}.${keyPostFix}`;
      return tOrEmpty(vueI18n, key, props);
    },
    tenantSpecificContentOrGeneric(keyPostFix, props) {
      return (
        this.tenantSpecificContent(keyPostFix, props) ||
        vueI18n.t(`tenantSpecificContent.generic.${keyPostFix}`, this.locale, {
          tenantId: this.tenantId,
          country: this.country,
          ...props
        })
      );
    },
    tenantSpecificContentExists(keyPostFix) {
      const key = `tenantSpecificContent.${this.tenantId}.${keyPostFix}`;
      return tOrEmpty(vueI18n, key);
    },
    /**
     * Retrieves a tenant-specific translation key with a fallback option.
     *
     * This method constructs a tenant-specific key based on the tenant ID and the provided key postfix.
     * It attempts to fetch the localized value for that key. If the key is not found,
     * it falls back to the provided fallback key or the key postfix if no fallback is specified.
     *
     * @param {string} keyPostFix - The postfix to append to the tenant-specific key.
     * @param {string} [fallback=undefined] - (Optional) A fallback key to use if the tenant-specific key is not found.
     *                                        If not provided, the key postfix is used as the fallback.
     * @returns {string} - The localized string for the tenant-specific key or the fallback key.
     *
     * @example
     * // Assuming tenantId is "123" and the locale system has the relevant keys:
     * // vueI18n.t('tenantSpecificContent.123.greeting') => 'Hello'
     * tenantSpecificWithFallback('greeting'); // Returns: 'Hello'
     *
     * // If the tenant-specific key is missing, but 'greeting' exists globally:
     * tenantSpecificWithFallback('nonExistentKey', 'greeting'); // Returns: 'Hello'
     *
     * // If neither tenant-specific nor fallback keys exist:
     * tenantSpecificWithFallback('nonExistentKey'); // Returns: ''
     */
    tenantSpecificWithFallback(keyPostFix, fallback = undefined) {
      const key = `tenantSpecificContent.${this.tenantId}.${keyPostFix}`;
      if (fallback === undefined) fallback = keyPostFix;
      return tOrEmpty(vueI18n, key) || vueI18n.t(fallback);
    },
    tcOrEmpty(key, props) {
      return translateOrEmpty(vueI18n.t, key, props);
    }
  });

  return {
    get $rttI18nInstance() {
      return $rttI18n;
    },
    install(app) {
      // eslint-disable-next-line no-console
      loadLocaleMessages(i18n, $rttI18n.locale).catch(console.warn);
      app.config.globalProperties.$t = vueI18n.t;
      app.config.globalProperties.$te = vueI18n.te; // be aware that this is not fallback aware
      app.config.globalProperties.$rttI18n = $rttI18n;
    }
  };
}
