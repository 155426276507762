import { defineStore } from 'pinia';
import { defaultThemeName } from '@/config/themes.js';

/**
 * @typedef {Object} ThemeState
 * @property {string | null} themeId - The theme id
 */
export const useThemeStore = defineStore('theme', {
  /**
   * @returns {ThemeState}
   */
  state: () => ({
    themeId: null
  }),
  getters: {
    isThemeBvb() {
      return this.themeId === 'bvb';
    }
  },
  actions: {
    setThemeId(themeId) {
      this.themeId = themeId || defaultThemeName();
    }
  }
});
