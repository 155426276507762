import { defineStore } from 'pinia';

export const useNpsStore = defineStore('nps', {
  state: () => ({
    npsConsent: false
  }),
  actions: {
    toggleNpsConsent() {
      this.npsConsent = !this.npsConsent;
    }
  }
});
