<template>
  <v-col class="pa-0">
    <div
      v-if="!this.isBelowMobileBreakpoint && !$store.$isWidget"
      :style="$rttI18n.isGlsItSelected ? { visibility: 'hidden' } : null"
      class="py-4"
    >
      <span class="font-weight-bold">{{ $t('overview.title') }}</span>
    </div>

    <EtaInformationBanner />
    <v-card
      class="card_big v-col-12 overview mb-6"
      data-testid="overview-card"
      variant="flat"
    >
      <div
        v-if="
          this.getTrackingState === TRACKING_DELIVERY_STATE_CLOSE ||
          this.getTrackingState === TRACKING_DELIVERY_STATE_FAR ||
          this.getTrackingState === TRACKING_DELIVERY_STATE_NEAR ||
          (this.getTrackingState === TRACKING_STATE_SHADOWED &&
            this.getTimeframe)
        "
      >
        <div
          v-if="this.getTrackingState === TRACKING_DELIVERY_STATE_FAR"
          class="text-footnote mb-4"
        >
          <span>
            {{
              $t('overview.details.stateFar', {
                stops: this.trackingData.trackingStateThresholdMax
              })
            }}
          </span>
        </div>
        <div
          v-if="
            this.getTrackingState === TRACKING_DELIVERY_STATE_CLOSE ||
            this.getTrackingState === TRACKING_DELIVERY_STATE_NEAR ||
            this.getTrackingState === TRACKING_STATE_SHADOWED
          "
          class="text-footnote mb-4"
        >
          <span v-if="this.getTimeframe">{{
            $rttI18n.tenantSpecificWithFallback(
              !this.isServiceTypePickup
                ? 'overview.details.deliveryTimestamp'
                : 'overview.details.pickupTimestamp'
            )
          }}</span>
          <span v-else>{{
            $t(
              !this.isServiceTypePickup
                ? 'overview.details.unclear.description'
                : 'overview.details.unclearPickup.description'
            )
          }}</span>
        </div>
        <h3 v-if="this.getTimeframe" class="text-h3">
          {{ this.timeFrameDesc }}
          <DynamicTourInfoIcon />
        </h3>
        <v-btn
          v-if="this.trackingData.timeFrameEligible"
          :block="true"
          :disabled="hasCustomizedDeliveryPeriod()"
          class="text-body-1 my-4"
          color="anchor"
          data-testid="btn-customizeDeliveryPeriod"
          variant="outlined"
          v-on:click="dialogHandler(dialogs.DELIVERY_PERIOD)"
        >
          <span>{{ $t('overview.details.customizeDeliveryPeriod') }}</span>
          <img
            alt="Delivered Period Icon"
            class="deliveryPeriodIcon"
            src="/icon_delivery_period.svg"
          />
        </v-btn>
        <hr v-else class="my-5" />
        <LastUpdatedComponent
          :updateTimestamp="this.trackingData.updateTimestamp"
        />
      </div>

      <div
        v-if="this.getTrackingState === this.TRACKING_DELIVERY_STATE_LAST"
        class="d-flex flex-column-reverse"
      >
        <LastUpdatedComponent
          :updateTimestamp="this.trackingData.updateTimestamp"
        />
        <hr class="my-5" />
        <h3 class="text-h6">
          {{ overviewHeader }}
        </h3>
      </div>

      <div
        v-if="
          this.getTrackingState === this.TRACKING_DELIVERY_STATE_UNCLEAR ||
          this.getTrackingState === TRACKING_STATE_BLOCKED ||
          (this.getTrackingState === TRACKING_STATE_SHADOWED &&
            !this.getTimeframe)
        "
        class="d-flex flex-column-reverse"
      >
        <LastUpdatedComponent
          :updateTimestamp="this.trackingData.updateTimestamp"
        />
        <hr class="my-5" />
        <h3 class="text-h6">
          {{ unclearHeader }}
        </h3>
      </div>

      <OverviewDeliveredComponent :postcode="postcode" />

      <OverviewPickedUpComponent :postcode="postcode" />

      <div v-if="this.getTrackingState === this.TRACKING_STATE_NOT_DELIVERED">
        <img
          :src="publicPath(`/themes/${this.themeId}/icon_delivery_refused.svg`)"
          alt="Icon not delivered"
          style="width: 60px"
        />
        <p class="header">
          <span class="font-weight-bold">{{
            $t('overview.details.notDelivered.header')
          }}</span>
        </p>
        <div>
          <hr class="my-5" />
          <p v-if="$rttI18n.isRoyalMailSelected" class="font-weight-bold mb-0">
            {{
              $rttI18n.tenantSpecificWithFallback(
                'overview.details.goBackToDetails'
              )
            }}
          </p>
          <p v-else>
            <span>{{
              $t(
                `overview.details.notDelivered.reasons.${this.trackingData.status.detail}`
              )
            }}</span>
          </p>
        </div>
      </div>

      <div v-if="this.getTrackingState === this.TRACKING_STATE_NOT_PICKED_UP">
        <img
          :src="publicPath(`/themes/${this.themeId}/icon_delivery_refused.svg`)"
          alt="Icon not delivered"
          style="width: 60px"
        />
        <p class="header">
          <span class="font-weight-bold">{{
            $t('overview.details.notPickedUp.header')
          }}</span>
        </p>
        <hr class="my-5" />
        <div
          :class="[
            $rttI18n.isGlsItSelected ? 'd-flex flex-column-reverse' : ''
          ]"
        >
          <LastUpdatedComponent
            :updateTimestamp="this.trackingData.updateTimestamp"
          />
          <hr class="my-5" />
          <span>{{
            $t(
              `overview.details.notPickedUp.reasons.${this.trackingData.status.detail}`
            )
          }}</span>
        </div>
      </div>

      <div v-if="this.getTrackingState === this.TRACKING_STATE_UNKNOWN">
        <h3 class="text-h6">
          {{ $t('overview.details.unclear.header') }}
        </h3>
        <hr class="my-5" />
        <LastUpdatedComponent
          :updateTimestamp="this.trackingData.updateTimestamp"
        />
      </div>
      <div
        v-if="
          (this.getTrackingState === this.TRACKING_STATE_DELIVERED ||
            $rttI18n.isGlsEsSelected) &&
          getTrackingNote()
        "
      >
        <hr class="my-5" />
        <span class="font-weight-bold" v-html="getTrackingNote()" />
      </div>
    </v-card>
    <div v-if="this.trackingData.tippingEnabled" class="tipping-container">
      <TippingComponent
        v-if="!tippingClosed"
        @closeTipping="this.handleCloseTipping"
      />
    </div>
    <div
      v-if="
        this.trackingData.toAddress &&
        this.trackingData.toAddress.postcode &&
        this.getTrackingState !== TRACKING_STATE_DELIVERED &&
        this.getTrackingState !== TRACKING_STATE_NOT_DELIVERED &&
        this.getTrackingState !== TRACKING_STATE_UNKNOWN
      "
    >
      <div
        v-if="
          this.isBelowMobileBreakpoint &&
          showCustomizationButtons &&
          this.trackingData.supportedCustomizedDeliveryTypes.length > 0 &&
          !(
            configGetter(this.$rttI18n.tenantId, this.themeId)
              .disableDepositPermission &&
            configGetter(this.$rttI18n.tenantId, this.themeId)
              .disableDeliverToNeighbour
          )
          // add disableSmsDelivery if sms are used again
        "
        class="my-4"
      >
        <v-btn
          :block="true"
          class="text-body-1"
          color="info"
          data-testid="btn-customizeDelivery"
          v-on:click="dialogHandler(dialogs.CUSTOMIZE)"
        >
          {{ $t('overview.details.customizeDelivery') }}
        </v-btn>
      </div>
      <div v-if="!isBelowMobileBreakpoint">
        <v-btn
          v-if="
            this.trackingData.supportedCustomizedDeliveryTypes.includes(
              DEPOSIT_PERMISSION
            ) &&
            !configGetter(this.$rttI18n.tenantId, this.themeId)
              .disableDepositPermission &&
            showCustomizationButtons
          "
          :block="true"
          class="text-body-1 my-4"
          color="info"
          variant="flat"
          v-on:click="dialogHandler(dialogs.DROP_OFF)"
        >
          {{ $t('customization.dropOffPermission.header') }}
        </v-btn>
        <v-btn
          v-if="
            this.trackingData.supportedCustomizedDeliveryTypes.includes(
              DELIVER_TO_NEIGHBOUR
            ) &&
            !configGetter(this.$rttI18n.tenantId, this.themeId)
              .disableDeliverToNeighbour &&
            showCustomizationButtons
          "
          :block="true"
          class="text-body-1 my-4"
          color="info"
          variant="flat"
          v-on:click="dialogHandler(dialogs.NEIGHBOUR)"
        >
          {{ $t('customization.neighbour.header') }}
        </v-btn>

        <v-btn
          v-if="
            this.trackingData.supportedCustomizedDeliveryTypes.includes(
              DELIVER_TO_PARCELSHOP
            ) &&
            !configGetter(this.$rttI18n.tenantId, this.themeId)
              .disableDeliverToNeighbour &&
            showCustomizationButtons
          "
          :block="true"
          class="text-body-1 my-4"
          color="info"
          variant="flat"
          v-on:click="dialogHandler(dialogs.DELIVER_TO_PARCELSHOP)"
        >
          {{ $t('customization.deliverToParcelShop.dialog.header') }}
        </v-btn>

        <v-btn
          v-if="
            !$store.$isWidget &&
            this.getTrackingState !== TRACKING_STATE_DELIVERED &&
            this.trackingData.supportedCustomizedDeliveryTypes.includes(
              DELIVERY_NOTE
            ) &&
            showCustomizationButtons
          "
          :block="true"
          class="text-body-1"
          color="info"
          variant="flat"
          v-on:click="dialogHandler(dialogs.DELIVERY_NOTE)"
        >
          {{ $t('customization.deliveryHints.header') }}
        </v-btn>
      </div>
      <div
        v-if="
          this.trackingData.smsNotification.type ===
            NOTIFICATION_TYPE_ELIGIBLE &&
          this.getTrackingState !== TRACKING_DELIVERY_STATE_LAST
        "
        class="actions my-4"
      >
        <v-btn
          :block="true"
          class="text-body-1"
          color="anchor"
          variant="outlined"
          v-on:click="dialogHandler(dialogs.NOTIFICATION_SUBSCRIPTION)"
        >
          {{ $t('customization.notificationSubscription.header') }}
        </v-btn>
      </div>
      <div
        v-if="this.trackingData.smsNotification.type === NOTIFICATION_TYPE_SET"
        class="pt-4"
      >
        <v-card class="card_small v-col-12">
          <span
            v-html="
              $t(
                'customization.notificationSubscription.info',
                this.trackingData.smsNotification
              )
            "
          />
        </v-card>
      </div>
      <div
        v-if="
          this.trackingData.pushNotification.state ===
            NOTIFICATION_TYPE_ELIGIBLE &&
          this.getTrackingState !== TRACKING_DELIVERY_STATE_LAST &&
          supportsPushNotification &&
          !$store.$isWidget
        "
        class="actions my-4"
      >
        <v-btn
          :block="true"
          class="text-body-1"
          color="anchor"
          variant="outlined"
          v-on:click="dialogHandler(dialogs.PUSH_NOTIFICATION_SUBSCRIPTION)"
        >
          {{ $t('pushNotification.button') }}
        </v-btn>
      </div>

      <div
        v-if="
          this.trackingData.pushNotification.state === NOTIFICATION_TYPE_SET
        "
        class="pt-4"
      >
        <v-card class="card_small v-col-12">
          <span
            v-html="
              $t('pushNotification.info', this.trackingData.pushNotification)
            "
          />
        </v-card>
      </div>
    </div>
  </v-col>
</template>

<script>
import {
  mapActions as mapActionsPinia,
  mapState as mapStatePinia
} from 'pinia';
import moment from 'moment-timezone';
import LastUpdatedComponent from './LastUpdatedComponent';
import OverviewDeliveredComponent from '@/components/OverviewDeliveredComponent';
import {
  DELIVER_TO_NEIGHBOUR,
  DELIVER_TO_PARCELSHOP,
  DELIVERY_NOTE,
  DEPOSIT_PERMISSION,
  NOTIFICATION_TYPE_ELIGIBLE,
  NOTIFICATION_TYPE_SET,
  TRACKING_DELIVERY_STATE_CLOSE,
  TRACKING_DELIVERY_STATE_FAR,
  TRACKING_DELIVERY_STATE_LAST,
  TRACKING_DELIVERY_STATE_NEAR,
  TRACKING_DELIVERY_STATE_UNCLEAR,
  TRACKING_STATE_BLOCKED,
  TRACKING_STATE_DELIVERED,
  TRACKING_STATE_DETAIL_NEIGHBOR,
  TRACKING_STATE_NOT_DELIVERED,
  TRACKING_STATE_NOT_PICKED_UP,
  TRACKING_STATE_SHADOWED,
  TRACKING_STATE_UNKNOWN
} from '@/store/constants';
import OverviewPickedUpComponent from '@/components/OverviewPickedUpComponent';
import { configGetter } from '@/config/configGetter';
import { getUtmSource } from '@/router/queryParam';
import { supportsPushNotification } from '@/services/pushNotification';
import TippingComponent from '@/components/TippingComponent.vue';
import publicPath from '@/util/publicPath';
import EtaInformationBanner from '@/components/EtaInformationBanner.vue';
import DynamicTourInfoIcon from '@/components/DynamicTourInfoIcon.vue';
import dialogs from '@/components/dialogs/dialogs.js';
import { useDialogStore } from '@/stores/dialog.js';
import { useResponsiveBreakpointsStore } from '@/stores/responsiveBreakpoints.js';
import { useDeliveryPeriodStore } from '@/stores/deliveryPeriod.js';
import { useThemeStore } from '@/stores/theme.js';
import { useTrackingDataStore } from '@/stores/trackingData.js';
import { useCustomizedDeliveryStore } from '@/stores/customizedDelivery.js';

export default {
  inheritAttrs: false,
  name: 'OverviewComponent',
  components: {
    DynamicTourInfoIcon,
    EtaInformationBanner,
    LastUpdatedComponent,
    OverviewDeliveredComponent,
    OverviewPickedUpComponent,
    TippingComponent
  },
  data() {
    return {
      postcode: this.$route.params.postcode || '',
      DELIVER_TO_NEIGHBOUR,
      DELIVERY_NOTE,
      DELIVER_TO_PARCELSHOP,
      DEPOSIT_PERMISSION,
      NOTIFICATION_TYPE_ELIGIBLE,
      NOTIFICATION_TYPE_SET,
      TRACKING_STATE_BLOCKED,
      TRACKING_DELIVERY_STATE_CLOSE,
      TRACKING_STATE_DELIVERED,
      TRACKING_STATE_DETAIL_NEIGHBOR,
      TRACKING_DELIVERY_STATE_FAR,
      TRACKING_DELIVERY_STATE_LAST,
      TRACKING_DELIVERY_STATE_NEAR,
      TRACKING_STATE_NOT_DELIVERED,
      TRACKING_STATE_NOT_PICKED_UP,
      TRACKING_DELIVERY_STATE_UNCLEAR,
      TRACKING_STATE_UNKNOWN,
      TRACKING_STATE_SHADOWED,
      supportsPushNotification,
      tippingClosed: false,
      dialogs
    };
  },
  computed: {
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data',
      getTrackingState: 'getTrackingState',
      getTimeframe: 'getTimeframe',
      isServiceTypePickup: 'isServiceTypePickup'
    }),
    ...mapStatePinia(useResponsiveBreakpointsStore, [
      'isBelowMobileBreakpoint'
    ]),
    ...mapStatePinia(useDeliveryPeriodStore, ['deliveryPeriod']),
    ...mapStatePinia(useThemeStore, ['themeId']),
    ...mapStatePinia(useCustomizedDeliveryStore, ['customizedDelivery']),
    timeFrameDesc() {
      const { from, to } = this.getTimeframe;

      return `${moment(from).format(this.$rttMoment.timeFormat)} - ${moment(
        to
      ).format(this.$rttMoment.timeFormat)}`;
    },
    overviewHeader() {
      return this.$t(
        this.isServiceTypePickup
          ? 'overview.details.pickupLastStop.header'
          : 'overview.details.deliveryLastStop.header'
      );
    },
    unclearHeader() {
      return this.$t(
        !this.isServiceTypePickup
          ? 'overview.details.unclear.header'
          : 'overview.details.unclearPickup.header'
      );
    },
    showCustomizationButtons() {
      return !this.customizedDelivery;
    }
  },
  methods: {
    ...mapActionsPinia(useDialogStore, ['toggleDialog']),
    publicPath,
    configGetter,
    getUtmSource: () => getUtmSource(this.$router),
    dialogHandler(dialog) {
      this.toggleDialog(dialog);
    },
    hasCustomizedDeliveryPeriod() {
      return !!this.deliveryPeriod;
    },
    handleCloseTipping() {
      this.tippingClosed = true;
    },
    getTrackingNote() {
      if (
        this.$rttI18n.tenantId &&
        !configGetter(this.$rttI18n.tenantId, this.themeId).disableMoreInfoLink
      ) {
        return this.$rttI18n.tenantSpecificContent('glsTrackingNote', {
          trackingId: this.trackingData.trackingId,
          postcode: this.postcode
        });
      }
      return '';
    }
  },
  watch: {
    '$route.params.postcode'(data) {
      this.postcode = data;
    }
  }
};
</script>

<style scoped>
.overview {
  margin-bottom: 10px;
  text-align: center;
}

.overview p {
  margin-bottom: 0;
  color: #000;
}

.overview .header {
  color: #061ab1;
  font-size: 16px;
}

.overview .v-icon {
  font-size: 36px;
  line-height: 48px;
}

.overview hr {
  border: none;
  border-bottom: 1px solid #e6e8f7;
}

button.secondary {
  color: #061ab1 !important;
  background-color: transparent !important;
  border: 1px solid #061ab1 !important;
}

.deliveryPeriodIcon {
  margin-bottom: 3px;
  margin-left: 3px;
  width: 26px;
  /* filter: brightness(0); */ /* https://codepen.io/sosuke/pen/Pjoqqp */
}

.tipping-container {
  margin-bottom: 1em;
}
</style>
