import { defineStore } from 'pinia';

/**
 * @typedef {'info' | 'error' | 'success' | null} MessageType
 */

/**
 * @typedef {Object} TrackingError
 * @property {string} [id] - The error identifier code (e.g., 'PARCEL-404')
 * @property {string} [reason] - A human-readable description of the error
 * @property {null|any} [payload] - Additional data related to the error, null if no data is available
 */

/**
 * @typedef {Object} AlertState
 * @property {TrackingError | string} msg - The message object
 * @property {MessageType} type - The type of message
 * @property {boolean} modal - The type of modal
 */
export const useAlertStore = defineStore('alert', {
  /**
   * @returns {AlertState}
   */
  state: () => ({
    msg: {},
    type: null,
    modal: false
  }),
  getters: {
    message(state) {
      return {
        msg: state.msg,
        type: state.type,
        modal: state.modal
      };
    }
  },
  actions: {
    /**
     * Set a new alert
     * @param {TrackingError | string} msg - The alert message object|string
     * @param {MessageType} type - The alert type
     * @param {boolean} modal - If the alert is in a modal or not
     */
    setAlert(msg, type, modal = false) {
      this.msg = msg;
      this.type = type;
      this.modal = modal;
    },
    clearAlert() {
      this.msg = {};
      this.type = null;
      this.modal = false;
    }
  }
});
