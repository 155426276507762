<template>
  <v-tooltip
    open-delay="150"
    location="right"
    offset="10"
    style="border-radius: 1px"
    max-width="300"
  >
    <template v-slot:activator="{ props }">
      <v-icon
        v-bind="props"
        color="black"
        size="medium"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        style="
          line-height: 1em;
          vertical-align: top;
          font-size: smaller;
          margin-top: 0.1em;
        "
        :icon="mdiInformationOutline"
      />
    </template>
    <span>{{ $t('etaInformation.bubble') }}</span>
  </v-tooltip>
</template>

<script>
import { sendAnalytics } from '@/services/analytics';
import { mapState as mapStatePinia } from 'pinia';
import { mdiInformationOutline } from '@mdi/js';
import { useTrackingDataStore } from '@/stores/trackingData.js';

export default {
  name: 'DynamicTourInfoIcon',
  data() {
    return {
      analyticsTimeout: null,
      mdiInformationOutline
    };
  },
  computed: {
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data'
    })
  },
  methods: {
    handleMouseEnter(e) {
      this.analyticsTimeout = setTimeout(() => {
        sendAnalytics(this.trackingData, 'eta', 'info_bubble_opened');
      }, 750);
    },
    handleMouseLeave(e) {
      clearTimeout(this.analyticsTimeout);
    }
  }
};
</script>

<style scoped></style>
