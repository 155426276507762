import { defineStore } from 'pinia';
import {
  LOCAL_STORAGE_DELIVERY_PERIOD_KEY,
  PROCESS_SEND_TIME_FRAME_FAILURE
} from '@/store/constants.js';
import { configGetter } from '@/config/configGetter.js';
import { fetchWithBaseParams } from '@/util/fetch.js';
import { useAlertStore } from '@/stores/alert.js';
import { useLoadingProcesses } from '@/stores/loadingProcesses.js';

export const useDeliveryPeriodStore = defineStore('deliveryPeriod', {
  state: () => ({
    deliveryPeriod: undefined
  }),
  actions: {
    updateCustomizedDeliveryPeriod(payload) {
      const key = `${LOCAL_STORAGE_DELIVERY_PERIOD_KEY}.${payload.trackingId}`;
      localStorage.setItem(key, payload.deliveryPeriod ?? '');
      this.deliveryPeriod = payload.deliveryPeriod;
    },
    readCustomizedDeliveryPeriod(payload) {
      const key = `${LOCAL_STORAGE_DELIVERY_PERIOD_KEY}.${payload.trackingId}`;
      this.deliveryPeriod = localStorage.getItem(key);
    },
    async sendTimeFrameFailure(payload) {
      const url = `${configGetter().API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/time-frame`;
      const body = { ...payload.body };

      const alertStore = useAlertStore();
      const loadingProcessesStore = useLoadingProcesses();
      try {
        alertStore.setAlert({}, null);
        loadingProcessesStore.incrementProcesses(
          PROCESS_SEND_TIME_FRAME_FAILURE
        );

        await fetchWithBaseParams(
          url,
          'PUT',
          payload,
          {},
          {
            body: JSON.stringify(body)
          }
        );
      } catch (e) {
        const msg = e.body ? { ...e.body } : e.message;
        alertStore.setAlert(msg, 'error', false);
      } finally {
        loadingProcessesStore.decrementProcesses(
          PROCESS_SEND_TIME_FRAME_FAILURE
        );
      }
    }
  }
});
