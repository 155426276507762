<template>
  <section
    v-if="this.getTrackingState === this.TRACKING_STATE_DELIVERED"
    class="overviewDelivered"
  >
    <div>
      <img
        alt="Delivery Completed"
        :src="
          publicPath(
            `/themes/${this.themeId}/icon_status_delivered_complete.svg`
          )
        "
        style="width: 60px"
      />
      <p class="header">
        <span class="pa-1 font-weight-bold">{{
          $t('overview.details.stateDelivered')
        }}</span>
      </p>
      <div
        v-if="
          this.getPrivateTrackingState &&
          this.trackingData.tenantId !== TENANT_IDS.ROYALMAIL_UK
        "
        class="mb-3"
      >
        <hr class="mb-3" />
        <div v-if="this.trackingData.status.detail">
          <span class="pa-1 font-weight-bold">{{ $t('generic.where') }}:</span>
          <span class="pa-1"
            >{{
              $t(
                `overview.details.delivered.details.${this.trackingData.status.detail}`
              )
            }}
          </span>
        </div>
        <div v-if="this.trackingData.status.name">
          <span class="pa-1 font-weight-bold">{{ $t('generic.name') }}: </span>
          <span class="pa-1">{{ this.trackingData.status.name }}</span>
        </div>
        <div class="ma-0" v-if="this.trackingData.status.address">
          <span
            class="pa-1 d-inline-block font-weight-bold"
            style="vertical-align: top"
          >
            {{ $t('generic.address') }}:
          </span>
          <span class="pa-1 d-inline-block">
            {{ this.trackingData.status.address.name }}
            <br />
            {{ this.trackingData.status.address.street }}
            {{ this.trackingData.status.address.number }}
            <br />
            {{ this.trackingData.status.address.postcode }}
            {{ this.trackingData.status.address.city }}
          </span>
        </div>
        <div class="ma-0" v-if="this.trackingData.status.deliveredAt">
          <span class="pa-1 font-weight-bold"> {{ $t('generic.when') }}: </span>
          <span class="pa-1">{{ this.getDeliveredAtTime }}</span>
        </div>
      </div>
    </div>
    <div>
      <div v-if="this.$rttI18n.isRoyalMailSelected">
        <br />
        <hr />
        <p class="font-weight-bold mb-0">
          {{
            $rttI18n.tenantSpecificWithFallback(
              'overview.details.goBackToDetails'
            )
          }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState as mapStatePinia } from 'pinia';
import {
  TRACKING_STATE_DELIVERED,
  TRACKING_STATE_DETAIL_NEIGHBOR,
  TRACKING_STATE_DETAIL_PARCEL_SHOP
} from '@/store/constants';
import publicPath from '@/util/publicPath';
import { TENANT_IDS } from '@/config/tenants.js';
import { useThemeStore } from '@/stores/theme.js';
import { useTrackingDataStore } from '@/stores/trackingData.js';

export default {
  name: 'OverviewDeliveredComponent',
  props: {
    postcode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      hasCalledDeliveryDetails: false,
      TRACKING_STATE_DELIVERED,
      TRACKING_STATE_DETAIL_NEIGHBOR,
      TRACKING_STATE_DETAIL_PARCEL_SHOP
    };
  },
  computed: {
    TENANT_IDS() {
      return TENANT_IDS;
    },
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data',
      getDeliveryStatusDetail: 'getDeliveryStatusDetail',
      getPrivateTrackingState: 'isPrivate',
      getTrackingState: 'getTrackingState'
    }),
    ...mapStatePinia(useThemeStore, ['themeId']),
    getDeliveredAtTime() {
      if (this.trackingData.status.deliveredAt) {
        return this.$moment(this.trackingData.status.deliveredAt).format(
          this.$rttMoment.dayTimeFormat
        );
      }
      return null;
    }
  },
  methods: {
    publicPath
  }
};
</script>

<style scoped>
.overview p,
.overview div {
  color: #000;
  font-size: 14px;
}

.overview hr {
  border: none;
  border-bottom: 1px solid #e6e8f7;
}
</style>
