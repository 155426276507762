export default Object.fromEntries(
  [
    'CUSTOMIZE',
    'DELIVERY_NOTE',
    'DELIVERY_PERIOD',
    'DROP_OFF',
    'NEIGHBOUR',
    'NOTIFICATION_SUBSCRIPTION',
    'PARCEL_SHOP',
    'PUSH_NOTIFICATION_SUBSCRIPTION',
    'TRUST_PILOT_CONSENT_DIALOG',
    'DELIVER_TO_PARCELSHOP'
  ].map(dialog => [dialog, dialog])
);
