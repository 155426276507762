<template>
  <div
    :class="['px-0', this.isBelowMobileBreakpoint ? 'py-2' : 'py-0']"
    v-if="this.adData !== null && !$store.$isWidget"
  >
    <div v-if="this.isBelowMobileBreakpoint">
      <AdItem
        v-if="this.adItems.length === 1"
        :adData="this.adItems[0]"
      ></AdItem>
      <v-carousel
        v-if="this.adItems.length > 1"
        height="auto"
        cycle
        hide-delimiter-background
        show-arrows="hover"
      >
        <v-carousel-item v-for="adItem in this.adItems" :key="adItem.banner_id">
          <AdItem :adData="adItem"></AdItem>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div v-else>
      <v-row dense class="flex-nowrap">
        <v-col
          :cols="12 / Math.max(this.adItems.length, 1)"
          v-for="adItem in this.adItems"
          :key="adItem.banner_id"
        >
          <AdItem :adData="adItem"></AdItem>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import AdItem from './AdItem';
import { mapState as mapStatePinia } from 'pinia';
import { useResponsiveBreakpointsStore } from '@/stores/responsiveBreakpoints.js';

export default {
  name: 'AdComponent',
  components: {
    AdItem
  },
  props: {
    adData: {
      default: null
    }
  },
  computed: {
    ...mapStatePinia(useResponsiveBreakpointsStore, [
      'isBelowMobileBreakpoint'
    ]),
    adItems() {
      if (!this.adData) return [];
      return Array.isArray(this.adData) ? this.adData : [this.adData];
    }
  }
};
</script>

<style scoped>
.videoButton {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/video-play-button.svg');
  background-position: center;
}
</style>
<style>
.v-carousel {
  padding-bottom: 45px;
}
</style>
