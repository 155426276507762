<template>
  <div>
    <v-row class="v-col-12 pa-0 ma-0">
      <div
        v-if="!this.isBelowMobileBreakpoint && !$store.$isWidget"
        class="v-row v-col-12"
      >
        <h2 class="pa-0 mr-4 my-4" style="padding: 0 0 30px">
          {{
            $t(
              $te('overview.headerDelivered')
                ? 'overview.headerDelivered'
                : 'overview.header'
            )
          }}
        </h2>
      </div>
      <div
        v-if="this.isBelowMobileBreakpoint && !$store.$isWidget"
        class="v-row my-0"
      >
        <v-col
          :style="$rttI18n.isGlsItSelected ? { visibility: 'hidden' } : null"
          class="d-flex text-right align-center font-weight-bold"
        >
          <div>{{ $t('overview.title') }}</div>
        </v-col>
        <v-col class="d-flex align-center justify-end" style="flex-basis: 50%">
          <span class="font-weight-bold">{{ $t('generic.trackingId') }}</span
          >: {{ this.trackingData.trackingId }}
        </v-col>
      </div>
      <v-col
        class="pa-0"
        :class="this.isBelowMobileBreakpoint ? 'v-col-12' : 'v-col-6'"
      >
        <MapContainer v-if="!this.isBelowMobileBreakpoint" />
        <AdComponent
          v-if="
            !this.isBelowMobileBreakpoint &&
            ((this.trackingData.ad && this.trackingData.ad.length) || 0) < 2
          "
          :adData="this.trackingData.ad"
        />
      </v-col>
      <v-col
        class="pa-0"
        :class="isBelowMobileBreakpoint ? 'v-col-12' : 'v-col-6 pl-4'"
      >
        <OverviewComponent
          @toggleDialog="dialogHandler"
          v-if="
            !configGetter(this.$rttI18n.tenantId, this.themeId).disableOverview
          "
        />
        <FeedbackComponent
          v-if="
            this.trackingData.toAddress &&
            !configGetter(this.$rttI18n.tenantId, this.themeId).disableOverview
          "
          :ratingData="this.trackingData.rating"
          :trackingId="this.trackingData.trackingId"
          :postcode="this.trackingData.toAddress.postcode"
        />
        <MapContainer v-if="this.isBelowMobileBreakpoint" />
        <AdComponent
          v-if="
            this.isBelowMobileBreakpoint &&
            !configGetter(this.$rttI18n.tenantId, this.themeId).disableOverview
          "
          :adData="this.trackingData.ad"
        />
        <DeliveryAddressComponent
          v-if="
            !configGetter(this.$rttI18n.tenantId, this.themeId).disableOverview
          "
        />
      </v-col>
    </v-row>
    <AdComponent
      v-if="
        !this.isBelowMobileBreakpoint &&
        (this.trackingData.ad && this.trackingData.ad.length) >= 2 &&
        !configGetter(this.$rttI18n.tenantId, themeId).disableOverview
      "
      :adData="this.trackingData.ad"
    />
  </div>
</template>

<script>
import AdComponent from '../AdComponent';
import DeliveryAddressComponent from '../DeliveryAddressComponent';
import FeedbackComponent from '../feedback/FeedbackComponent';
import MapContainer from '@/components/map/MapContainer';
import OverviewComponent from '../OverviewComponent';
import { configGetter } from '@/config/configGetter';
import {
  mapActions as mapActionsPinia,
  mapState as mapStatePinia
} from 'pinia';
import { useDialogStore } from '@/stores/dialog.js';
import { useResponsiveBreakpointsStore } from '@/stores/responsiveBreakpoints.js';
import { useThemeStore } from '@/stores/theme.js';
import { useTrackingDataStore } from '@/stores/trackingData.js';

export default {
  name: 'RealTimeTrackingFinished',
  components: {
    AdComponent,
    DeliveryAddressComponent,
    FeedbackComponent,
    MapContainer,
    OverviewComponent
  },
  data() {
    return {
      configGetter
    };
  },
  computed: {
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data'
    }),
    ...mapStatePinia(useThemeStore, ['themeId']),
    ...mapStatePinia(useResponsiveBreakpointsStore, ['isBelowMobileBreakpoint'])
  },
  methods: {
    ...mapActionsPinia(useDialogStore, ['toggleDialog']),
    dialogHandler(dialog) {
      this.toggleDialog(dialog);
    }
  }
};
</script>

<style scoped></style>
