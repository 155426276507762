import tenants from './tenants.js';
import locales from './locales.js';

export const fallbackLocale = 'en';
export const supportedCountries = Object.values(tenants).map(
  it => it.country.code
);

export const supportedTenants = Object.keys(tenants);
export const supportedLocales = Object.keys(locales);

// TODO remove as soon country is not present in URL anymore
/**
 * @param {string} input Converts countries to tenant
 * @returns {string|undefined} tenant
 */
export function inputToTenant(input) {
  if (input?.length === 2 && supportedCountries.includes(input.toUpperCase())) {
    return `gls-${input.toLowerCase()}`;
  }

  return input;
}

// TODO remove as soon tenantId is not present in URL anymore
/**
 * @param {string} input Converts string to tenantId
 * @returns {string|undefined} tenantId iso or undefined
 */
export function inputToCountry(input) {
  if (input?.length === 2) return input; // is country;

  if (input?.startsWith('gls-')) return tenants[input]?.country.code;

  return input;
}

export default {
  locales,
  fallbackLocale,
  supportedTenants,
  supportedLocales
};
