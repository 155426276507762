import { defineStore } from 'pinia';
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '@/store/constants.js';

export const useResponsiveBreakpointsStore = defineStore(
  'responsiveBreakpoints',
  {
    state: () => ({
      isBelowMobileBreakpoint: window.innerWidth < MOBILE_BREAKPOINT,
      isBelowTabletBreakpoint: window.innerWidth < TABLET_BREAKPOINT
    }),
    actions: {
      setIsBelowMobileBreakpoint(isBelowMobileBreakpoint) {
        this.isBelowMobileBreakpoint = isBelowMobileBreakpoint;
      },
      setIsBelowTabletBreakpoint(isBelowTabletBreakpoint) {
        this.isBelowTabletBreakpoint = isBelowTabletBreakpoint;
      }
    }
  }
);
