import { inputToTenant } from '@/config/countriesAndLocales.js';

const buildUrlWithQueryParams = (url, queryParams = {}) => {
  if (!queryParams || Object.keys(queryParams).length === 0) {
    return url;
  }

  const searchParams = new URLSearchParams();

  // Add each query parameter to URLSearchParams
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        // Handle array values by adding multiple entries with the same key
        value.forEach(item => searchParams.append(key, item));
      } else {
        searchParams.append(key, value);
      }
    }
  });

  // Append the query string to the URL
  const queryString = searchParams.toString();
  if (queryString) {
    return url + (url.includes('?') ? '&' : '?') + queryString;
  }

  return url;
};

const replacePathParams = (url, payload) => {
  return url
    .replace('{tenant}', inputToTenant(payload.tenantId))
    .replace('{postcode}', payload.postcode)
    .replace('{trackingId}', payload.trackingId);
};

/**
 * Fetch with base parameters and headers
 * @param {string} url - URL with path parameters
 *  @param {'GET'|'POST'|'PUT'|'PATCH'|'DELETE'|'HEAD'|'OPTIONS'} method -
 *  HTTP method * @param {Object} payload - Object containing values for path parameters and headers
 * @param {Object} payload - Object containing values for path parameters and headers
 * @param {Object} queryParams - Object containing query parameters
 * @param {Object} options - Additional fetch options
 * @returns {Promise<Response>} Fetch response
 */
export const fetchWithBaseParams = async (
  url,
  method,
  payload,
  queryParams = {},
  options = {}
) => {
  const baseHostname = new URL(
    parent !== window ? document.referrer : document.location.href
  ).hostname;

  // replace path parameters and add query parameters
  let processedUrl = replacePathParams(url, payload);
  processedUrl = buildUrlWithQueryParams(processedUrl, queryParams);

  const headers = {
    'Content-Type': 'application/json',
    'x-original-hostname': baseHostname,
    'x-original-utm-source': payload.utmSource || null,
    'x-original-referrer-hostname': document.referrer
      ? new URL(document.referrer).hostname
      : '',
    ...options.headers
  };

  const fetchOptions = {
    ...options,
    method,
    headers
  };

  const response = await fetch(processedUrl, fetchOptions);

  if (!response.ok) {
    const error = new Error(response.statusText);
    error.status = response.status;

    try {
      error.body = await response.json();
    } catch (jsonError) {
      error.message = response.statusText;
    }

    throw error;
  }

  return response.json();
};

// this is only used for adding push notifications
export async function fetchWithCallback(callback, payload) {
  const response = await callback(payload);
  if (!response.ok) {
    const error = new Error(response.statusText);
    error.status = response.status;

    try {
      error.body = await response.json();
    } catch (jsonError) {
      error.message = response.statusText;
    }

    throw error;
  }

  return response.json();
}
