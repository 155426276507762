import { defineStore } from 'pinia';
import { useTrackingDataStore } from '@/stores/trackingData.js';
import { configGetter } from '@/config/configGetter.js';

let reconnectTimeout = null;

/**
 * @typedef {Object} WebsocketState
 * @property {boolean} isSubscriptionActive - To check if subscription is active or not
 * @property {boolean} isUpdateAvailable - To check if any update is available
 */
export const useWebsocketStore = defineStore('websocket', {
  /**
   *
   * @returns {WebsocketState}
   */
  state: () => ({
    socket: null,
    isSubscriptionActive: false,
    isUpdateAvailable: false
  }),
  actions: {
    setSubscriptionActive(isActive) {
      this.isSubscriptionActive = isActive;
    },
    setIsUpdateAvailable(isAvailable) {
      this.isUpdateAvailable = isAvailable;
    },

    connectSocket(onopen) {
      const socket = new WebSocket(`${configGetter().WEBSOCKET_URL}`);

      socket.onmessage = ({ data }) => {
        const dataObject = JSON.parse(data);
        switch (dataObject.action) {
          case 'SUBSCRIBED':
            this.isSubscriptionActive = true;
            break;
          case 'NOT_SUBSCRIBED':
            this.isSubscriptionActive = false;
            break;
          case 'DRIVER_LOCATION_CHANGE':
            this.isUpdateAvailable = true;
            break;
          default:
        }
      };
      socket.onopen = () => {
        this.socket = socket;
        if (reconnectTimeout !== null) {
          clearTimeout(reconnectTimeout);
        }
        onopen();
      };
      socket.onclose = () => {
        this.socket = null;
        reconnectTimeout = setTimeout(
          () => {
            this.connectSocket(onopen);
          },
          Math.floor(Math.random() * (10000 - 1000 + 1) + 1000)
        );
      };
    },
    subscribeToUpdates(isPrivate) {
      if (this.isSubscriptionActive === false) {
        const trackingDataStore = useTrackingDataStore();
        const request = {
          tenantId: trackingDataStore.tenantId,
          jobId: trackingDataStore.trackingId
        };
        if (isPrivate) {
          request.action = 'PRIVATE_SUBSCRIBE';
          request.postcode = trackingDataStore.toAddress.postcode;
        } else {
          request.action = 'PUBLIC_SUBSCRIBE';
        }

        this.socket?.send(JSON.stringify(request));
      }
    },
    initializeUpdates(isPrivate) {
      const trackingDataStore = useTrackingDataStore();

      if (!trackingDataStore.enabledWebSocketUpdates) {
        return;
      }
      if (this.socket === null) {
        this.connectSocket(() => this.subscribeToUpdates(isPrivate));
      } else {
        this.subscribeToUpdates(isPrivate);
      }
    },
    unSubscribeToUpdates() {
      this.isSubscriptionActive = false;
    }
  }
});
