<template>
  <v-col v-if="this.customizedDelivery" class="pa-0">
    <div :class="this.isBelowMobileBreakpoint ? 'py-2' : 'py-4'">
      <span class="font-weight-bold">{{
        $t('customization.display.header')
      }}</span>
    </div>
    <v-card
      v-if="
        this.customizedDelivery?.type === DELIVER_TO_NEIGHBOUR ||
        this.customizedDelivery.type === DEPOSIT_PERMISSION
      "
      class="card_small mx-0 v-col-12 pa-4"
      variant="flat"
    >
      <p class="pa-0">
        <span
          v-if="this.customizedDelivery?.type === DELIVER_TO_NEIGHBOUR"
          class="font-weight-bold"
        >
          {{ $t('customization.neighbour.header') }}
        </span>
        <span
          v-if="this.customizedDelivery.type === DEPOSIT_PERMISSION"
          class="font-weight-bold"
        >
          {{ $t('customization.dropOffPermission.header') }}
        </span>
        <br />
        <span class="text-body-2">
          {{ this.customizedDelivery.location
          }}{{ this.customizedDelivery.fullName }}<br />
          {{ this.customizedDelivery.description }}
        </span>
      </p>
      <img src="/check_pad.png" />
    </v-card>
    <DeliveryNote
      v-else-if="this.customizedDelivery?.type === DELIVERY_NOTE"
      :delivery-note="this.customizedDelivery.description"
    />
    <ParcelShopRedirect
      v-else-if="this.customizedDelivery?.type === DELIVER_TO_PARCELSHOP"
    />
  </v-col>
</template>

<script>
import {
  DELIVER_TO_NEIGHBOUR,
  DELIVERY_NOTE,
  DEPOSIT_PERMISSION,
  DELIVER_TO_PARCELSHOP
} from '@/store/constants';
import DeliveryNote from '@/components/DeliveryNote.vue';
import ParcelShopRedirect from '@/components/ParcelShopRedirect.vue';
import { mapState as mapStatePinia } from 'pinia';
import { useResponsiveBreakpointsStore } from '@/stores/responsiveBreakpoints.js';
import { useCustomizedDeliveryStore } from '@/stores/customizedDelivery.js';

export default {
  name: 'CustomizedDeliveryData',
  components: { DeliveryNote, ParcelShopRedirect },
  computed: {
    ...mapStatePinia(useResponsiveBreakpointsStore, [
      'isBelowMobileBreakpoint'
    ]),
    ...mapStatePinia(useCustomizedDeliveryStore, ['customizedDelivery'])
  },
  data() {
    return {
      DELIVER_TO_NEIGHBOUR,
      DEPOSIT_PERMISSION,
      DELIVERY_NOTE,
      DELIVER_TO_PARCELSHOP
    };
  }
};
</script>

<style scoped>
.card_small {
  display: flex;
  justify-content: space-between;
}

img {
  height: 5em;
  align-self: center;
  margin-left: 1em;
}
</style>
