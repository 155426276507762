<template>
  <section v-if="this.hasTrackingId">
    <v-progress-linear
      v-if="isRefreshing"
      :indeterminate="true"
      color="primary"
    ></v-progress-linear>
    <RealTimeTrackingInProgress v-if="this.isJobInProgress" />
    <RealTimeTrackingFinished v-if="this.isJobFinished" />
  </section>
  <section v-else>
    <h2 style="font-weight: 300; padding: 0 0 30px">
      {{ $t('overview.headerLoading') }}
    </h2>
    <v-progress-linear color="primary" indeterminate></v-progress-linear>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mapActions as mapActionsPinia,
  mapState as mapStatePinia
} from 'pinia';
import {
  TRACKING_DELIVERY_STATE_CLOSE,
  TRACKING_DELIVERY_STATE_FAR,
  TRACKING_DELIVERY_STATE_LAST,
  TRACKING_DELIVERY_STATE_NEAR,
  TRACKING_DELIVERY_STATE_UNCLEAR,
  TRACKING_STATE_BLOCKED,
  TRACKING_STATE_DELIVERED,
  TRACKING_STATE_NOT_DELIVERED,
  TRACKING_STATE_NOT_PICKED_UP,
  TRACKING_STATE_PICKED_UP,
  TRACKING_STATE_SHADOWED,
  TRACKING_STATE_UNKNOWN,
  UPDATE_TRACKING_EVENT
} from '@/store/constants';
import RealTimeTrackingInProgress from './RealTimeTrackingInProgress';
import RealTimeTrackingFinished from './RealTimeTrackingFinished';
import loadConfigByThemeId from '@/config/themes';
import { configGetter } from '@/config/configGetter';
import { getUtmSource } from '@/router/queryParam.js';
import { useGdprStore } from '@/stores/gdpr.js';
import { useThemeStore } from '@/stores/theme.js';
import { useTrackingDataStore } from '@/stores/trackingData.js';
import { useWebsocketStore } from '@/stores/websocket.js';
import { useDeliveryPeriodStore } from '@/stores/deliveryPeriod.js';

export default {
  name: 'RealTimeTrackingContainer',
  components: {
    RealTimeTrackingInProgress,
    RealTimeTrackingFinished
  },
  props: {
    postcode: {
      type: String,
      required: false
    },
    trackingId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      TRACKING_DELIVERY_STATE_CLOSE,
      TRACKING_DELIVERY_STATE_FAR,
      TRACKING_DELIVERY_STATE_LAST,
      TRACKING_DELIVERY_STATE_NEAR,
      TRACKING_DELIVERY_STATE_UNCLEAR,
      TRACKING_STATE_BLOCKED,
      TRACKING_STATE_DELIVERED,
      TRACKING_STATE_NOT_DELIVERED,
      TRACKING_STATE_NOT_PICKED_UP,
      TRACKING_STATE_PICKED_UP,
      TRACKING_STATE_SHADOWED,
      TRACKING_STATE_UNKNOWN,
      isLoading: false,
      isRefreshing: false,
      refreshingMinDelta: 30000
    };
  },
  computed: {
    ...mapStatePinia(useGdprStore, ['hasGdprConsent']),
    ...mapStatePinia(useThemeStore, ['themeId']),
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data',
      getTrackingState: 'getTrackingState'
    }),
    ...mapState(['message']),
    themeConfig() {
      return loadConfigByThemeId(this.themeId);
    },
    isJobInProgress() {
      return [
        TRACKING_DELIVERY_STATE_CLOSE,
        TRACKING_DELIVERY_STATE_FAR,
        TRACKING_DELIVERY_STATE_LAST,
        TRACKING_DELIVERY_STATE_NEAR,
        TRACKING_DELIVERY_STATE_UNCLEAR,
        TRACKING_STATE_BLOCKED,
        TRACKING_STATE_NOT_DELIVERED,
        TRACKING_STATE_NOT_PICKED_UP,
        TRACKING_STATE_SHADOWED,
        TRACKING_STATE_UNKNOWN
      ].includes(this.getTrackingState);
    },
    isJobFinished() {
      return [TRACKING_STATE_DELIVERED, TRACKING_STATE_PICKED_UP].includes(
        this.getTrackingState
      );
    },
    gdprConsent() {
      return (
        this.hasGdprConsent(this.$rttI18n.tenantId) ||
        configGetter(this.$rttI18n.tenantId, this.themeId).gdprApproved
      );
    },
    hasTrackingId() {
      return Object.hasOwn(this.trackingData, 'trackingId');
    }
  },
  methods: {
    configGetter,
    ...mapActionsPinia(useGdprStore, ['setGdprConsent']),
    ...mapActionsPinia(useTrackingDataStore, [
      'loadTrackingDataById',
      'loadAdditionalTrackingData'
    ]),
    ...mapActionsPinia(useDeliveryPeriodStore, [
      'readCustomizedDeliveryPeriod'
    ]),
    ...mapActionsPinia(useWebsocketStore, [
      'subscribeToUpdates',
      'unSubscribeToUpdates'
    ]),
    ...mapActions(['readCustomizedDeliveryPeriodState']),
    updateTracking(override) {
      const key = 'lastUpdate';
      const isInBackground = document.visibilityState !== 'visible';
      const shouldUpdate =
        new Date() - (window.sessionStorage.getItem(key) || 0) >
        this.refreshingMinDelta;
      if ((!isInBackground && shouldUpdate) || override === true) {
        const initialTitle = document.title;
        document.title = this.$t('overview.updatingTitle');
        window.sessionStorage.setItem(key, Date.now());
        this.isRefreshing = true;

        this.reloadTrackingData().finally(() => {
          this.isRefreshing = false;
          document.title = initialTitle;
        });
      }
    },
    setRoute(newTrackingData) {
      if (
        newTrackingData.toAddress &&
        newTrackingData.toAddress.postcode &&
        !this.$route.params.postcode
      ) {
        this.$router.push({
          name: 'realTimeTracking',
          params: {
            trackingId: this.$route.trackingId,
            postcode: newTrackingData.toAddress.postcode,
            countryOrTenantId: this.$rttI18n.tenantId,
            locale: this.$rttI18n.locale
          }
        });
      }
    },
    async reloadTrackingData() {
      if (this.postcode) {
        return this.loadAdditionalTrackingData({
          utmSource: getUtmSource(this.$route),
          tenantId: this.$rttI18n.tenantId,
          postcode: this.postcode,
          trackingId: this.trackingId
        });
      } else {
        return this.loadTrackingDataById({
          utmSource: getUtmSource(this.$route),
          tenantId: this.$rttI18n.tenantId,
          trackingId: this.trackingId
        });
      }
    },
    loadTrackingData() {
      if (this.isLoading) {
        return;
      }
      if (this.postcode && !this.gdprConsent) {
        this.$router.push({
          name: 'realTimeTracking',
          params: {
            trackingId: this.$route.trackingId,
            countryOrTenantId: this.$rttI18n.tenantId,
            locale: this.$rttI18n.locale
          },
          query: { utm_source: getUtmSource(this.$route) }
        });
      }
      if (this.postcode && this.gdprConsent) {
        if (
          !this.trackingData.toAddress ||
          this.trackingData.toAddress.postcode !== this.postcode ||
          this.trackingIdHasChanged()
        ) {
          this.isLoading = true;
          this.unSubscribeToUpdates();
          this.loadAdditionalTrackingData({
            utmSource: getUtmSource(this.$route),
            tenantId: this.$rttI18n.tenantId,
            postcode: this.postcode,
            trackingId: this.trackingId
          });
        }
      } else {
        if (this.trackingIdHasChanged()) {
          this.isLoading = true;
          this.unSubscribeToUpdates();
          this.loadTrackingDataById({
            utmSource: getUtmSource(this.$route),
            tenantId: this.$rttI18n.tenantId,
            trackingId: this.trackingId
          });
        }
      }
    },
    handleVisibilityChange() {
      this.updateTracking(false);
    },
    trackingIdHasChanged() {
      return (
        this.trackingData.trackingId !== this.trackingId &&
        this.trackingData.parcelNumber !== this.trackingId
      );
    }
  },
  watch: {
    message(message) {
      if (message && message.msg) {
        if (message.msg.id === 'PARCEL-404') {
          this.$router.push({
            name: 'landing',
            params: {
              countryOrTenantId: this.$rttI18n.tenantId,
              locale: this.$rttI18n.locale
            }
          });
        }
        if (
          !this.trackingData.trackingId &&
          this.trackingId &&
          message.msg.id === 'PARCEL-1'
        ) {
          this.loadTrackingDataById({
            utmSource: getUtmSource(this.$route),
            tenantId: this.$rttI18n.tenantId,
            trackingId: this.trackingId
          });
          this.$router.push({
            name: 'realTimeTracking',
            params: {
              trackingId: this.trackingId,
              countryOrTenantId: this.$rttI18n.tenantId,
              locale: this.$rttI18n.locale
            }
          });
        }
      }
    },
    postcode(newPostcode, oldPostcode) {
      if (newPostcode && newPostcode !== oldPostcode) {
        this.loadTrackingData();
      }
    },
    trackingId(newTrackingId, oldTrackingId) {
      if (newTrackingId && newTrackingId !== oldTrackingId) {
        this.loadTrackingData();
        this.readCustomizedDeliveryPeriod({ trackingId: newTrackingId });
      }
    }
  },
  mounted() {
    if (this.trackingIdHasChanged() || !this.gdprConsent) {
      this.loadTrackingData();
    }
    this.$mitt.on(UPDATE_TRACKING_EVENT, () => {
      this.updateTracking(true);
    });

    const trackingDataStore = useTrackingDataStore();
    trackingDataStore.$subscribe((mutation, state) => {
      this.isLoading = false;
      this.setRoute(state.data);
    });
  },
  created() {
    this.readCustomizedDeliveryPeriod({ trackingId: this.trackingId });
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('focus', this.handleVisibilityChange);
  },
  unmounted() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
    window.removeEventListener('focus', this.handleVisibilityChange);
  }
};
</script>

<style scoped></style>
