<template>
  <div
    id="rtt-paypal-container"
    :class="{
      'disabled-overlay': amount === undefined && isPaypalScriptLoaded
    }"
  >
    <span v-if="!isPaypalScriptLoaded">Loading..</span>
  </div>
</template>

<script>
import { mapState as mapStatePinia } from 'pinia';
import { loadScript } from '@paypal/paypal-js';
import { sendAnalytics as _sendAnalytics } from '@/services/analytics';
import { letIf } from '@/util/scopeFunctions.js';
import { useTrackingDataStore } from '@/stores/trackingData.js';

export default {
  name: 'PaypalButton',
  components: {},
  props: {
    amount: {
      type: Number
    }
  },
  data: function () {
    return {
      isPaypalScriptLoaded: false
    };
  },
  computed: {
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data'
    })
  },
  methods: {
    sendAnalytics(eventName, payload) {
      return _sendAnalytics(this.trackingData, 'tipping', eventName, payload);
    },
    renderPaypalButton(paypal) {
      try {
        paypal
          .Buttons({
            style: {
              shape: 'pill'
            },
            onClick: data => {
              this.onClickData = data;
            },
            createOrder: (data, actions) => {
              const createOrderData = {
                currency: 'EUR',
                amount: this.amount,
                paypalData: { ...data, ...(this.onClickData || {}) }
              };

              const address = letIf(this.trackingData.toAddress, it => ({
                address_line_1: `${it.street} ${it.number}`,
                admin_area_1: it.city,
                admin_area_2: it.city,
                postal_code: it.postcode,
                country_code: it.country
              }));

              return actions.order
                .create({
                  payee: {
                    merchant_id: '35UAGPY985ZBC'
                  },
                  payer: {
                    name: {
                      given_name:
                        this.trackingData.toAddress?.name.split(' ')[0],
                      surname: this.trackingData.toAddress?.name.split(' ')[1]
                    },
                    address
                    // email_address: 'customer@domain.com',
                    // phone: {
                    //   phone_type: 'MOBILE',
                    //   phone_number: {
                    //     national_number: '14082508100'
                    //   }
                    // }
                  },
                  purchase_units: [
                    {
                      reference_id: this.trackingData.parcelNumber,
                      amount: {
                        currency_code: 'EUR',
                        value: this.amount.toString()
                      },
                      shipping: { address }
                    }
                  ]
                })
                .then(orderId => {
                  this.sendAnalytics('order_created', {
                    ...createOrderData,
                    orderId
                  });
                  return orderId;
                });
            },
            onApprove: (data, actions) => {
              const captureOrderData = {
                currency: 'EUR',
                amount: this.amount,
                paypalData: data
              };
              this.sendAnalytics('order_approved', captureOrderData);

              const capturePromise = actions.order.capture().then(details => {
                this.sendAnalytics('order_captured', details);
                return details;
              });
              setTimeout(() => this.$emit('paymentSuccess'), 1);
              return capturePromise;
            },
            onCancel: (data, actions) => {
              this.sendAnalytics('order_cancelled', data);
            },
            onError: err => {
              this.sendAnalytics('error', { message: err.message });
              // eslint-disable-next-line no-console
              console.error(err);
            }
          })
          .render('#rtt-paypal-container');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('failed to render the PayPal Buttons', error);
      }
    }
  },
  mounted() {
    const clientId = import.meta.env.VITE_APP_PAYPAL_CLIENT_ID;
    if (!this.paypal) {
      try {
        this.paypal = loadScript({
          locale: this.$rttI18n
            .getLocaleWithDefaultCountryCode()
            .replace('-', '_'),
          clientId,
          currency: 'EUR',
          disableFunding: [
            'paylater',
            'blik',
            'eps',
            'giropay',
            'ideal',
            'mercadopago',
            'mybank',
            'p24',
            'sepa',
            'sofort',
            'venmo'
          ]
        }).then(paypal => {
          this.isPaypalScriptLoaded = true;
          this.renderPaypalButton(paypal);
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('failed to load the PayPal JS SDK script', error);
      }
    }
  }
};
</script>
<style scoped>
.disabled-overlay {
  max-width: 735px;
  position: relative;
  margin-bottom: -8px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.3;
    z-index: 1000;
    border-radius: 2em;
  }
}
</style>
