<template>
  <section
    v-if="this.getTrackingState === this.TRACKING_STATE_PICKED_UP"
    class="pickedUp"
  >
    <div>
      <img
        alt="Icon Pickup"
        src="/icon_status_pickup_complete.svg"
        style="width: 60px"
      />
      <p class="header">
        <span class="font-weight-bold">{{
          $t('overview.details.statePickedUp')
        }}</span>
      </p>
      <div v-if="this.getPrivateTrackingState" class="mb-3">
        <hr class="mb-3" />
        <v-row class="ma-0" v-if="this.trackingData.status.detail">
          <v-col class="pa-1 text-right font-weight-bold" cols="4"
            >{{ $t('generic.where') }}:</v-col
          >
          <v-col class="pa-1 text-left" cols="8">
            {{
              $t(
                `overview.details.pickedUp.details.${this.trackingData.status.detail}`
              )
            }}
          </v-col>
        </v-row>
        <v-row class="ma-0" v-if="this.trackingData.status.name">
          <v-col class="pa-1 text-right font-weight-bold" cols="4"
            >{{ $t('generic.name') }}:
          </v-col>
          <v-col class="pa-1 text-left" cols="8">
            {{ this.trackingData.status.name }}
          </v-col>
        </v-row>
        <v-row class="ma-0" v-if="this.trackingData.status.address">
          <v-col class="pa-1 text-right font-weight-bold" cols="4">
            <strong>{{ $t('generic.address') }}:</strong>
          </v-col>
          <v-col class="pa-1 text-left" cols="8">
            {{ this.trackingData.status.address.name }}
            <br />
            {{ this.trackingData.status.address.street }}
            {{ this.trackingData.status.address.number }}
            <br />
            {{ this.trackingData.status.address.postcode }}
            {{ this.trackingData.status.address.city }}
          </v-col>
        </v-row>
        <v-row class="ma-0" v-if="this.trackingData.status.deliveredAt">
          <v-col class="pa-1 text-right font-weight-bold" cols="4">
            <strong>{{ $t('generic.when') }}:</strong>
          </v-col>
          <v-col class="pa-1 text-left" cols="8">
            {{ this.getDeliveredAtTime }}
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mapActions as mapActionsPinia,
  mapState as mapStatePinia
} from 'pinia';
import {
  TRACKING_STATE_DETAIL_NEIGHBOR,
  TRACKING_STATE_DETAIL_PARCEL_SHOP,
  TRACKING_STATE_PICKED_UP
} from '@/store/constants';
import { useTrackingDataStore } from '@/stores/trackingData.js';

export default {
  name: 'OverviewPickedUpComponent',
  props: {
    postcode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      hasCalledDeliveryDetails: false,
      TRACKING_STATE_PICKED_UP,
      TRACKING_STATE_DETAIL_NEIGHBOR,
      TRACKING_STATE_DETAIL_PARCEL_SHOP
    };
  },
  computed: {
    ...mapStatePinia(useTrackingDataStore, {
      trackingData: 'data',
      getDeliveryStatusDetail: 'getDeliveryStatusDetail',
      getPrivateTrackingState: 'isPrivate',
      getTrackingState: 'getTrackingState'
    }),
    getDeliveredAtTime() {
      if (!this.trackingData.status.deliveredAt) return null;

      return this.$moment(this.trackingData.status.deliveredAt).format(
        this.$rttMoment.dayTimeFormat
      );
    }
  },
  methods: {
    ...mapActionsPinia(useTrackingDataStore, ['loadAdditionalTrackingData']),
    wrapAdditionalDataLoader() {
      if (
        !this.postcode ||
        this.hasCalledDeliveryDetails ||
        (this.getDeliveryStatusDetail !== this.TRACKING_STATE_DETAIL_NEIGHBOR &&
          this.getDeliveryStatusDetail !==
            this.TRACKING_STATE_DETAIL_PARCEL_SHOP) ||
        this.trackingData.status.name ||
        this.trackingData.status.address
      ) {
        return;
      }
      this.hasCalledDeliveryDetails = true;

      this.loadAdditionalTrackingData({
        postcode: this.postcode,
        parcelNumber: this.trackingData.parcelNumber
      });
    }
  },
  mounted() {
    this.wrapAdditionalDataLoader();

    const trackingDataStore = useTrackingDataStore();
    this.unwatchTrackingData = this.$watch(
      () => trackingDataStore.data,
      (newTrackingData, oldTrackingData) => {
        if (
          oldTrackingData &&
          newTrackingData.parcelNumber !== oldTrackingData.parcelNumber
        ) {
          this.hasCalledDeliveryDetails = false;
        }
        this.wrapAdditionalDataLoader();
      }
    );
  },
  unmounted() {
    this.unwatchTrackingData();
  }
};
</script>

<style scoped>
.overview p,
.overview div {
  color: #000;
  font-size: 14px;
}

.overview hr {
  border: none;
  border-bottom: 1px solid #e6e8f7;
}
</style>
