import {
  ACTION_TYPE_ERROR,
  PROCESS_ADD_CUSTOMIZED_DELIVERY,
  PROCESS_ADD_NOTIFICATION_SUBSCRIPTION,
  PROCESS_ADD_RATING,
  PROCESS_LOAD_ADDITIONAL_TRACKING_DATA,
  PROCESS_LOAD_TRACKING_DATA
} from './constants';
import { subscribe } from '@/services/pushNotification';
import { configGetter } from '@/config/configGetter.js';
import { fetchWithBaseParams, fetchWithCallback } from '@/util/fetch.js';

let reconnectTimeout = null;

export function createActions() {
  return {
    async loadTrackingDataById({ commit, dispatch }, payload) {
      const url = `${configGetter().API_URL}/tenant/{tenant}/parcel/{trackingId}`;

      try {
        commit('updateMessage', {});
        commit('incrementProcesses', PROCESS_LOAD_TRACKING_DATA);

        const response = await fetchWithBaseParams(url, 'GET', payload);

        commit('setUpdateAvailable', false);
        commit('updateTrackingData', response);
        dispatch('initializeUpdates', false);
      } catch (e) {
        commit('updateFailure', {
          PROCESS_LOAD_TRACKING_DATA,
          modal: false,
          msg: e.body ? { ...e.body } : e.message,
          type: ACTION_TYPE_ERROR
        });
      } finally {
        commit('decrementProcesses', PROCESS_LOAD_TRACKING_DATA);
      }
    },
    async loadAdditionalTrackingData({ commit, dispatch }, payload) {
      const url = `${configGetter().API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}`;

      try {
        commit('updateMessage', {});
        commit('incrementProcesses', PROCESS_LOAD_ADDITIONAL_TRACKING_DATA);

        const response = await fetchWithBaseParams(url, 'GET', payload);

        commit('setUpdateAvailable', false);
        commit('updatePersonalTrackingData', response);
        dispatch('initializeUpdates', true);
      } catch (e) {
        commit('updateFailure', {
          PROCESS_LOAD_ADDITIONAL_TRACKING_DATA,
          modal: false,
          msg: e.body ? { ...e.body } : e.message,
          type: ACTION_TYPE_ERROR
        });
        commit('setSubscriptionActive', false);
      } finally {
        commit('decrementProcesses', PROCESS_LOAD_ADDITIONAL_TRACKING_DATA);
      }
    },
    async addCustomizedDelivery({ commit, dispatch }, payload) {
      const url = `${configGetter().API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/customization`;
      const body = { ...payload.body };

      try {
        commit('updateMessage', {});
        commit('incrementProcesses', PROCESS_ADD_CUSTOMIZED_DELIVERY);

        const response = await fetchWithBaseParams(
          url,
          'PUT',
          payload,
          {},
          {
            body: JSON.stringify(body)
          }
        );

        commit('updateCustomizedDelivery', response);
      } catch (e) {
        commit('updateFailure', {
          PROCESS_ADD_CUSTOMIZED_DELIVERY,
          modal: true,
          msg: e.body ? { ...e.body } : e.message,
          type: ACTION_TYPE_ERROR
        });
      } finally {
        commit('decrementProcesses', PROCESS_ADD_CUSTOMIZED_DELIVERY);
      }
    },
    async addRating({ commit, dispatch }, payload) {
      const url = `${configGetter().API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/rating`;
      const body = { ...payload.body };

      try {
        commit('updateMessage', {});
        commit('incrementProcesses', PROCESS_ADD_RATING);

        const response = await fetchWithBaseParams(
          url,
          'PUT',
          payload,
          {},
          {
            body: JSON.stringify(body)
          }
        );

        commit('updateRatingData', response);
      } catch (e) {
        commit('updateFailure', {
          PROCESS_ADD_RATING,
          modal: false,
          msg: e.body ? { ...e.body } : e.message,
          type: ACTION_TYPE_ERROR
        });
      } finally {
        commit('decrementProcesses', PROCESS_ADD_RATING);
      }
    },
    async addNotificationSubscription({ commit, dispatch }, payload) {
      const url = `${configGetter().API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}/notification/sms`;
      const body = { ...payload.body };

      try {
        commit('updateMessage', {});
        commit('incrementProcesses', PROCESS_ADD_NOTIFICATION_SUBSCRIPTION);

        const response = await fetchWithBaseParams(
          url,
          'PUT',
          payload,
          {},
          {
            body: JSON.stringify(body)
          }
        );

        commit('updateNotificationSubscription', response);
      } catch (e) {
        const modal = e.status !== 404;
        commit('updateFailure', {
          PROCESS_ADD_NOTIFICATION_SUBSCRIPTION,
          modal,
          msg: e.body ? { ...e.body } : e.message,
          type: ACTION_TYPE_ERROR
        });
      } finally {
        commit('decrementProcesses', PROCESS_ADD_NOTIFICATION_SUBSCRIPTION);
      }
    },
    async addPushNotification({ commit, dispatch }, payload) {
      commit('updateMessage', {});
      dispatch('startProcess', PROCESS_ADD_NOTIFICATION_SUBSCRIPTION);

      try {
        const response = await fetchWithCallback(subscribe, payload);
        commit('updatePushNotification', response);
      } catch (e) {
        commit('updateFailure', {
          PROCESS_ADD_NOTIFICATION_SUBSCRIPTION,
          modal: e.status !== '404',
          msg: e.message,
          type: ACTION_TYPE_ERROR
        });
        throw e;
      } finally {
        commit('decrementProcesses', PROCESS_ADD_NOTIFICATION_SUBSCRIPTION);
      }
    },
    setMessage({ commit }, message) {
      commit('updateMessage', message);
    },
    setRouteParams({ commit }, payload) {
      commit('updateRouteParams', payload);
    },
    toggleNPSConsent({ commit }) {
      commit('toggleNPSConsent');
    },
    startProcess({ commit }, scope) {
      commit('incrementProcesses', scope);
    },
    stopProcess({ commit }, scope) {
      commit('decrementProcesses', scope);
    },
    async connectSocket({ commit, dispatch, state }, onopen) {
      const socket = new WebSocket(`${configGetter().WEBSOCKET_URL}`);
      socket.onmessage = ({ data }) => {
        const dataObject = JSON.parse(data);
        switch (dataObject.action) {
          case 'SUBSCRIBED':
            commit('setSubscriptionActive', true);
            break;
          case 'NOT_SUBSCRIBED':
            commit('setSubscriptionActive', false);
            break;
          case 'DRIVER_LOCATION_CHANGE':
            commit('setUpdateAvailable', true);
            break;
          default:
        }
      };
      socket.onopen = () => {
        commit('setSocket', socket);
        if (reconnectTimeout !== null) {
          clearTimeout(reconnectTimeout);
        }
        onopen();
      };
      socket.onclose = () => {
        commit('setSocket', null);
        reconnectTimeout = setTimeout(
          () => {
            dispatch('connectSocket', onopen);
          },
          Math.floor(Math.random() * (10000 - 1000 + 1) + 1000)
        );
      };
    },
    async subscribeToUpdates({ commit, dispatch, state }, isPrivate) {
      if (state.isSubscriptionActive === false) {
        const request = {
          tenantId: state.trackingData.tenantId,
          jobId: state.trackingData.trackingId
        };
        if (isPrivate) {
          request.action = 'PRIVATE_SUBSCRIBE';
          request.postcode = state.trackingData.toAddress.postcode;
        } else {
          request.action = 'PUBLIC_SUBSCRIBE';
        }

        state.socket?.send(JSON.stringify(request));
      }
    },
    async initializeUpdates({ commit, dispatch, state }, isPrivate) {
      if (!state.trackingData.enabledWebSocketUpdates) {
        return;
      }
      if (state.socket === null) {
        dispatch('connectSocket', () =>
          dispatch('subscribeToUpdates', isPrivate)
        );
      } else {
        dispatch('subscribeToUpdates', isPrivate);
      }
    },
    async unSubscribeToUpdates({ commit, dispatch }, payload) {
      commit('setSubscriptionActive', false);
    }
  };
}
